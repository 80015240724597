import React from "react";
import {Form, Radio} from "antd";
import {WeightUnit} from "../../../../constants/constants";

const WeightUnitSwitcher = ({onChange, label}) => {
    return (<>
        <Form.Item label={label} name="WeightUnit"
                   className="ant-form-item-without-validation double-line-label">
            <Radio.Group optionType="button" buttonStyle="solid" onChange={onChange}>
                <Radio.Button value={WeightUnit.Pounds}>Pounds</Radio.Button>
                <Radio.Button value={WeightUnit.Kilograms}>Kilograms</Radio.Button>
            </Radio.Group>
        </Form.Item>
    </>);
};

export default WeightUnitSwitcher;