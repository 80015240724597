import React, {memo} from "react";
import _ from "lodash";
import TreeSelectFilter from "./Filters/TreeSelectFilter";
import {Form} from "antd";

export const noInventoryDepartmentsOption = {value: "— No Departments —", title: "— No Departments —"};
export const noInventoryItemTypesOption = {value: "— No Item Types —", title: "— No Item Types —"};
export const noInventoryItemKeywordsOption = {value: "— No Item Keywords —", title: "— No Item Keywords —"};

const InventoriesBlock = ({
                              value, onChanged, onChange, disabled, form, resources
                          }) => {

    const departments = _.chain(resources)
        .map((n) => n.DepartmentTag)
        .compact()
        .uniqBy((x) => x.AccountTagId)
        .sortBy((x) => x.Name)
        .value();

    const itemTypes = _.chain(resources)
        .flatMap((n) => n.ItemTypeTags)
        .uniqBy((x) => x.AccountTagId)
        .sortBy((x) => x.Name)
        .value();

    const itemKeywords = _.chain(resources)
        .flatMap((n) => n.ItemKeywordTags)
        .uniqBy((x) => x.AccountTagId)
        .sortBy((x) => x.Name)
        .value();

    const departmentOption = "All Departments";
    const itemTypeOption = "All Item Types";
    const itemKeywordOption = "All Item Keywords";

    const departmentGroups = [{
        title: departmentOption,
        key: departmentOption,
        value: departmentOption,
        filterOff: true,
        children: [noInventoryDepartmentsOption, ...departments.map((d) => ({
            value: d.AccountTagId.toString(), title: d.Name
        }))],
    },];

    const itemTypeGroups = [{
        title: itemTypeOption,
        key: itemTypeOption,
        value: itemTypeOption,
        filterOff: true,
        children: [noInventoryItemTypesOption, ...itemTypes.map((type) => ({
            value: type.AccountTagId.toString(), title: type.Name
        }))],
    },];

    const itemKeywordsGroups = [{
        title: itemKeywordOption,
        key: itemKeywordOption,
        value: itemKeywordOption,
        filterOff: true,
        children: [noInventoryItemKeywordsOption, ...itemKeywords.map((type) => ({
            value: type.AccountTagId.toString(), title: type.Name
        }))],
    },];

    return (<>
        <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal"
              size="middle">
            <Form.Item
                label="Departments"
                name={["Inventory", "DepartmentTags"]}
                className="ant-form-item-without-validation">
                <TreeSelectFilter
                    treeDefaultExpandedKeys={[departmentOption]}
                    value={value}
                    disabled={disabled}
                    treeData={departmentGroups}
                    placeholder="All Departments"
                    onChanged={(selectedValue) => {
                        form.setFieldsValue({Inventory: {DepartmentTags: selectedValue}});
                        onChanged(selectedValue);
                    }}
                />
            </Form.Item>
            <Form.Item label="Types"
                       name={["Inventory", "ItemTypeTags"]}
                       className="ant-form-item-without-validation">
                <TreeSelectFilter
                    treeDefaultExpandedKeys={[itemTypeOption]}
                    value={value}
                    disabled={disabled}
                    treeData={itemTypeGroups}
                    placeholder="All Types"
                    onChanged={(selectedValue) => {
                        form.setFieldsValue({Inventory: {ItemTypeTags: selectedValue}});
                        onChanged(selectedValue);
                    }}
                />
            </Form.Item>
            <Form.Item label="Keywords"
                       name={["Inventory", "ItemKeywordTags"]}
                       className="ant-form-item-without-validation">
                <TreeSelectFilter
                    treeDefaultExpandedKeys={[itemKeywordOption]}
                    value={value}
                    disabled={disabled}
                    treeData={itemKeywordsGroups}
                    placeholder="All Keywords"
                    onChanged={(selectedValue) => {
                        form.setFieldsValue({Inventory: {ItemKeywordTags: selectedValue}});
                        onChanged(selectedValue);
                    }}
                />
            </Form.Item>
        </Form>
    </>);
};

export default memo(InventoriesBlock);