import React from 'react';
import {Button} from 'antd';
import {AppstoreOutlined, FileTextOutlined, CheckCircleOutlined, PlusOutlined} from '@ant-design/icons';
import IconButton from '../IconButton/IconButton';
import './AppFooter.scss';

const AppFooter = ({setIsQrScannerOpen}) => {

    const handleScanQrBarCodeButtonClick = () => {
        setIsQrScannerOpen(true);
    };

    return (
        <>
            <Button type="primary" shape="circle" size="large"
                    icon={<PlusOutlined style={{fontSize: '31px', color: 'white'}}/>}
                    className="scan-qr-bar-code-button"
                    onClick={handleScanQrBarCodeButtonClick}
            />
            <IconButton icon={<AppstoreOutlined/>} text="Inventory"/>
            <IconButton icon={<FileTextOutlined/>} text="Receipts"/>
            <IconButton icon={<CheckCircleOutlined/>} text="Task"/>
        </>
    );
};

export default AppFooter;