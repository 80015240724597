import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";
import ColorTag from "../../../../Common/ColorTag/ColorTag";

const noItem = {
    value: "noItem", title: "— No Colors —", sortRank: 1,
};

function getRoles(data) {
    if (!data || data.length === 0) {
        return [noItem];
    }

    return [noItem, ..._.chain(data)
        .map((d) => d.ItemColorTags)
        .flatten()
        .uniqBy((x) => x.AccountTagId)
        .map((item) => ({value: item.AccountTagId, title: item.Name, color: item.Icon}))
        .value(),];
}

const ColorsFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState();

    const items = useMemo(() => {
        return getRoles(data);
    }, [data]);

    const rolesHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [rolesHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [rolesHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        setFilterHandler((item) => _.intersection(item.ItemColorTags.map((x) => x.AccountTagId), filterValues).length > 0 || (isNoItemSelected && item.ItemColorTags.length === 0));

        setSelectedItems(selectedItems);
    }

    return (
        <MultipleFilterWithSearchFilter
            selectedItems={selectedItems}
            items={items}
            allItemsText="All Colors"
            onChanged={onChanged}
            search
            renderItem={(item) => <ColorTag
                color={item.color}
                text={item.title}
                width="13px"
                height="13px"/>}
        />
    );
};

export default ColorsFilter;