import api from "../api";
import store from "../redux/store";
import {setProjectGroupsExtended, batchMergeTags} from "../redux/reducers/tags";

const {dispatch} = store;

export const loadTags = () => {
    let projectIds = store.getState().projects.projects.map((p) => p.Id) || [];
    let accountId = store.getState().projects.activePortfolio.Id;

    return api
        .post(`api/Tag/GetAccountKnown?accountId=${accountId}`, projectIds)
        .then((tagResponse) => dispatch(batchMergeTags(tagResponse)))
        .catch(err => console.error(err));
}
export const loadProjectTags = (projectIds = []) => api
    .post(`api/Tag/GetProjectTags`, {ProjectIds: projectIds, Types: [3]})
    .then((tagResponse) => {
        dispatch(setProjectGroupsExtended(tagResponse || []))
    })

export const createTag = (payload) => api
    .post(`api/Tag/Create`, payload)
    .then((response) => loadTags().then(() => response));

export const updateTag = (payload) => api
    .post(`api/Tag/Update`, payload)
    .then((response) => loadTags().then(() => response));

export const mergeTag = (payload) => api
    .post(`api/Tag/Merge`, payload)
    .then(loadTags);
