import {TagsFilled} from "@ant-design/icons";
import {Modal, Form, message} from "antd";
import React, {useState} from "react";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TagType} from "../../../../constants/constants";
import {TagIcon} from "../../../../helpers/Tags";
import {setSelected} from "../../../../redux/reducers/mainTable";
import {mergeTag, loadTags} from "../../../../services/tags";
import {SelectWithFormItem} from "../../../Common/Input";

const tagName = {
    [TagType.Category]: "Category",
    [TagType.Department]: "Department",
    [TagType.Group]: "Group",
    [TagType.ItemKeyword]: "Item Keyword",
    [TagType.ItemColor]: "Item Color",
    [TagType.ItemType]: "Item Type",
    [TagType.Role]: "Role",
    [TagType.TaskStatus]: "Status",
    null: "",
};

const tagNames = {
    [TagType.Category]: "Categories",
    [TagType.Department]: "Departments",
    [TagType.Group]: "Groups",
    [TagType.ItemKeyword]: "Item Keyword",
    [TagType.ItemType]: "Item Type",
    [TagType.ItemColor]: "Item Color",
    [TagType.Role]: "Roles",
    [TagType.TaskStatus]: "Statuses",
    null: "",
};

const MergeTag = ({onClose, visible}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const selectedTagType = useSelector((state) => state.settings.selectedTagType);
    const selectedTags = useSelector((store) => store.mainTable.selected) || [];

    const [_empty, setTarget] = useState(0);

    const tags = {
        [TagType.Category]: useSelector((state) => state.tags.categories),
        [TagType.Department]: useSelector((state) => state.tags.departments),
        [TagType.Group]: useSelector((state) => state.tags.groups),
        [TagType.ItemKeyword]: useSelector((state) => state.tags.itemKeywords),
        [TagType.ItemType]: useSelector((state) => state.tags.itemTypes),
        [TagType.ItemColor]: useSelector((state) => state.tags.itemColors),
        [TagType.Role]: useSelector((state) => state.tags.roles),
        [TagType.TaskStatus]: useSelector((state) => state.tags.statuses),
    };

    useEffect(() => {
        if (visible) {
            form.resetFields();
            if (selectedTags.length === 1) {
                form.setFieldsValue({Merge: selectedTags[0].AccountTagId});
                setTarget(selectedTags[0].AccountTagId);
            }
        }
    }, [visible]);

    const items = tags[selectedTagType];

    const toRemoveTag = items.find((i) => i.AccountTagId === form.getFieldValue("Merge"));
    const intoTag = items.find((i) => i.AccountTagId === form.getFieldValue("Into"));

    const handleOk = async () => {
        let formResult;
        try {
            formResult = await form.validateFields();
        } catch (err) {
        }

        if (!formResult) return;
        try {
            setLoading(true);
            await mergeTag({
                AccountId: activePortfolio.Id,
                FromTagId: toRemoveTag.AccountTagId,
                ToTagId: intoTag.AccountTagId,
            });
            await loadTags();
            dispatch(setSelected([]));
            form.resetFields();
            message.success("Merge successful.");
            onClose();
        } catch (err) {
            message.error("Server error");
        } finally {
            setLoading(false);
        }
    };

    const close = () => {
        setLoading(false);
        onClose();
    };

    const getTagLabel = (tag) => {
        if (selectedTagType === TagType.TaskStatus || selectedTagType === TagType.ItemColor) {
            return (
                <>
                    <TagIcon icon={tag.Icon}/> {tag.Name}
                </>
            );
        }

        return tag.Name || " ";
    };
    const mapTags = (disabled) =>
        items.map((tag) => ({
            label: getTagLabel(tag),
            value: tag.AccountTagId,
            disabled: tag.AccountTagId === disabled?.AccountTagId,
        }));

    return (
        <Modal
            confirmLoading={isLoading}
            title={
                <>
                    <TagsFilled/> Merge {tagNames[selectedTagType]}
                </>
            }
            visible={visible}
            maskClosable={false}
            onOk={handleOk}
            onCancel={close}
            okText="Merge">
            <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                <SelectWithFormItem
                    allowClear
                    showSearch
                    hasFeedback
                    label="Tag to Remove"
                    name="Merge"
                    mode="single"
                    style={{width: "100%"}}
                    rules={[{required: true, message: "Please select a tag to remove"}]}
                    placeholder="Select a tag to remove"
                    onChanged={(val) => setTarget(val)}
                    options={mapTags(intoTag)}></SelectWithFormItem>
                <SelectWithFormItem
                    allowClear
                    showSearch
                    hasFeedback
                    label="Tag to Keep"
                    name="Into"
                    mode="single"
                    style={{width: "100%"}}
                    rules={[{required: true, message: "Please select a tag to keep"}]}
                    placeholder="Select a tag to keep"
                    onChanged={(val) => setTarget(val)}
                    options={mapTags(toRemoveTag)}></SelectWithFormItem>
            </Form>
            <p>
                {toRemoveTag && intoTag && (
                    <>
                        All instances of the {tagName[selectedTagType].toLowerCase()} {toRemoveTag.Name} will be
                        replaced with {intoTag.Name}.
                        This cannot be undone!
                    </>
                )}
            </p>
        </Modal>
    );
};

export default MergeTag;
