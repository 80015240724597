import React, {useState} from 'react';
import {Button, message} from 'antd';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import './QrBarcodeScanner.scss';
import {getResourceByGetByQrOrBarCode} from '../../../services/resources';
import {useSelector} from 'react-redux';
import {errorFromHttpResponse} from "../../../helpers/error";
import CodeIsUnassignedModal from "./CodeIsUnassignedModal/CodeIsUnassignedModal";
import NewResourceItemModal from "./NewResourceItemModal/NewResourceItemModal";

const QrBarcodeScanner = ({isQrScannerOpen, setIsQrScannerOpen}) => {
    const [isUnassignedModalVisible, setIsUnassignedModalVisible] = useState(false);
    const [isNewResourceItemModalVisible, setIsNewResourceItemModalVisible] = useState(false);
    const [scannedResult, setScannedResult] = useState(null);

    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    const handleScanResult = (result) => {
        const base64Text = window.btoa(result.text);
        setScannedResult({...result, text: base64Text});

        setIsQrScannerOpen(false);

        getResourceByGetByQrOrBarCode(base64Text, activePortfolioId)
            .then((resource) => {
                if (resource) {
                    message.success(`Resource found: ${resource.Name}`);
                } else {
                    setIsUnassignedModalVisible(true);
                }
            })
            .catch(handleScanError);
    };

    const handleScanError = (err) => {
        const errorText = errorFromHttpResponse(err);
        message.error(errorText);
    };

    const handleScan = (err, result) => {
        if (result) {
            handleScanResult(result);
        }
    };

    const handleCreateNewItem = () => {
        setIsUnassignedModalVisible(false);
        setIsNewResourceItemModalVisible(true);
    };

    return (<>
        {isQrScannerOpen && (<div className="qr-scanner-overlay">
            <BarcodeScannerComponent width={500} height={500} onUpdate={handleScan}/>
            <Button type="primary" onClick={() => setIsQrScannerOpen(false)}
                    className="qr-scanner-close-button">
                Close
            </Button>
        </div>)}

        <CodeIsUnassignedModal
            isModalVisible={isUnassignedModalVisible}
            setIsModalVisible={setIsUnassignedModalVisible}
            handleCreateNewItem={handleCreateNewItem}
        />

        <NewResourceItemModal
            isNewResourceItemModalVisible={isNewResourceItemModalVisible}
            setIsNewResourceItemModalVisible={setIsNewResourceItemModalVisible}
            scanResult={scannedResult}
        />
    </>);
};

export default QrBarcodeScanner;