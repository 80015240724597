import React, {useState, memo} from "react";
import _ from "lodash";
import {useEffect} from "react";
import {getNotes} from "../../../services/reports";
import TreeSelectFilter from "./Filters/TreeSelectFilter";

export const noNoteDepartmentsOption = {value: "— No Departments —", title: "— No Departments —"};
const NotesBlock = ({value, onChanged, onChange, disabled, selectedProjects}) => {

    const [notes, setNotes] = useState([]);
    const selectedProjectMap = selectedProjects.join(",");

    useEffect(() => {
        getNotes(selectedProjects).then((resp) => setNotes(resp));
    }, [selectedProjectMap]);

    const departments = _.chain(notes)
        .flatMap((n) => n.DepartmentTags)
        .uniqBy((x) => x.AccountTagId)
        .sortBy((x) => x.Name)
        .value();
    
    const departmentOption = "All Departments";
    const groups = [
        {
            title: "All Departments",
            key: departmentOption,
            value: "All Departments",
            filterOff: true,
            children: [noNoteDepartmentsOption, ...departments.map((d) => ({
                value: d.AccountTagId.toString(),
                title: d.Name
            }))],
        },
    ];

    function onItemsChanged(filterValues) {
        onChange(filterValues);
        onChanged();
    }

    return (
        <TreeSelectFilter
            treeDefaultExpandedKeys={[departmentOption]}
            value={value}
            disabled={disabled}
            treeData={groups}
            placeholder="All Departments"
            onChanged={onItemsChanged}></TreeSelectFilter>
    );
};

export default memo(NotesBlock);
