import React from "react";
import ChangedAtFilter from "../../../Common/Tables/FilterBar/ChangedAtFilter/ChangedAtFilter";
import FilterBar from "../../../Common/Tables/FilterBar";
import SearchFilter from "./SearchFilter";
import GroupBy from "./GroupBy";
import DepartmentsFilter from "./DepartmentsFilter";
import TypesFilter from "./TypesFilter";
import KeywordsFilter from "./KeywordsFilter";
import ColorsFilter from "./ColorsFilter";

const ItemsFilterBar = (props) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[DepartmentsFilter, TypesFilter, ColorsFilter, KeywordsFilter, ChangedAtFilter, SearchFilter]}
            GroupByComponent={GroupBy}
        />
    );
};

export default ItemsFilterBar;
