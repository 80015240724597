import React, {useState, useEffect} from "react";
import {Input, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";

const {Title} = Typography;

const EditableTitle = ({itemData, onSave}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState("");

    useEffect(() => {
        if (itemData && itemData.Name) {
            setName(itemData.Name);
        }
    }, [itemData]);

    const handleSave = () => {
        setIsEditing(false);
        onSave(name);
    };

    return isEditing ? (
        <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleSave}
            onPressEnter={handleSave}
            autoFocus
        />
    ) : (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Title
                level={5}
                className="title"
                style={{marginBottom: 0, display: "inline-flex", alignItems: "center"}}
                onClick={() => setIsEditing(true)}
            >
                {name || "Loading..."}
                <EditOutlined
                    style={{cursor: "pointer", color: "#108FE8", marginLeft: 4, fontSize: "18px"}}
                    onClick={() => setIsEditing(true)}
                />
            </Title>
        </div>
    );
};

export default EditableTitle;