import React, {useState, useEffect} from 'react';
import {Button, Typography} from 'antd';
import {LeftOutlined, MoreOutlined} from '@ant-design/icons';
import {getResource} from '../../../services/resources';
import EditableTitle from './EditableTitle/EditableTitle';
import {useParams, useHistory} from 'react-router-dom';
import './ResourceEdit.scss';
import TakePhoto from "../TakePhoto/TakePhoto";
import ColorTag from "../../Common/ColorTag/ColorTag";
import {infinity} from "../../../helpers/text";
import _ from "lodash";
import {sanitizeHTML} from "../../../helpers/html";

const {Title, Text} = Typography;

const ResourceEdit = () => {
    const [itemData, setItemData] = useState(null);
    const [isTakePhotoVisible, setIsTakePhotoVisible] = useState(false);
    const [photo, setPhoto] = useState(null);
    const history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        let isMounted = true;

        if (id) {
            getResource(id)
                .then((data) => {
                    if (isMounted) {
                        setItemData(data);
                    }
                })
                .catch((error) => console.error('Error loading item data:', error));
        }

        return () => {
            isMounted = false;
        };
    }, [id]);

    const fields = [{label: 'Sources & Quantities', key: 'Sources'}, {
        label: 'Total Qty.',
        key: 'Sources',
        isQuantity: true
    }, {label: 'Dpt', key: 'DepartmentTag.Name'}, {
        label: 'Types',
        key: 'ItemTypeTags',
        isArray: true
    }, {label: 'Keywords', key: 'ItemKeywordTags', isArray: true}, {
        label: 'Colors',
        key: 'ItemColorTags',
        isArray: true,
        isColorTag: true
    }, {label: 'Size', key: 'Size'}, {label: 'Weight', key: 'Weight'}, {
        label: 'Details (Private)',
        key: 'Description'
    }, {label: 'Details (Public)', key: 'DescriptionPublic'},];

    const getFieldValue = (field) => {
        if (!itemData) return '';

        const value = _.get(itemData, field.key);

        if (field.isColorTag) {
            return (value || []).map((x) => <ColorTag key={x.Name} color={x.Icon} text={x.Name}/>);
        }

        if (field.isQuantity) {
            const unlimited = value.filter((m) => !m.Quantity && m.Quantity !== 0);
            const outOfStock = value.filter((m) => m.Quantity === 0);
            const present = value.filter((m) => m.Quantity > 0);

            if (present.length + outOfStock.length === 0 && unlimited.length > 0) return infinity;

            return _.sum(value.map((m) => m.Quantity || 0));
        }

        if (field.isArray) {
            return (value || []).map((x) => x.Name).join(', ');
        }

        if (field.key === 'Sources') {
            return value.map((source) => `${source.Name}`).join(', ');
        }

        if (field.key === 'Description' || field.key === 'DescriptionPublic') {
            return sanitizeHTML(value);
        }

        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(value);
        }

        return value;
    };

    const handleTakePhoto = () => {
        setIsTakePhotoVisible(true);
    };

    const handleCapture = (imageSrc) => {
        setPhoto(imageSrc);
        setIsTakePhotoVisible(false);
    };

    const handleCloseTakePhoto = () => {
        setIsTakePhotoVisible(false);
    };

    return (<div className="inventory-page">
        <div className="header">
            <div className="header-container">
                <div
                    className="header-left"
                    onClick={() => history.push('/campaign')}
                >
                    <LeftOutlined className="icon"/>
                    <Title
                        level={5}
                        className="title"
                        style={{marginBottom: 0}}
                    >
                        Inventory
                    </Title>
                </div>
                <EditableTitle
                    itemData={itemData}
                    onSave={(newName) => {
                        setItemData({...itemData, Name: newName});
                    }}
                />
                <MoreOutlined className="icon"/>
            </div>
            {photo && <img src={photo} alt="Captured"/>}
            <Button
                type="primary"
                className="photo-button"
                onClick={handleTakePhoto}
            >
                Take a Photo
            </Button>
        </div>
        <div className="fields">
            {fields.map((field) => (<div key={field.label} className="row">
                <div className="field">
                    <Text className="field-text">{field.label}</Text>
                </div>
                <div className="value">
                    {field.key === 'Description' || field.key === 'DescriptionPublic' ? (
                        <div dangerouslySetInnerHTML={{__html: getFieldValue(field)}}/>) : (
                        <Text>{getFieldValue(field)}</Text>)}
                </div>
            </div>))}
        </div>
        <TakePhoto
            visible={isTakePhotoVisible}
            onClose={handleCloseTakePhoto}
            onCapture={handleCapture}
        />
    </div>);
};

export default ResourceEdit;