import _ from "lodash";

export const FieldType = Object.freeze({
    Name: "Name",
    Department: "DepartmentTag",
    Description: "Description",
    DescriptionPublic: "DescriptionPublic",
    Source: "Source",
    SourceDetails: "SourceDetails",
    Quantity: "Quantity",
    Cost: "Cost",
    Unit: "Unit",
    Size: "Size",
    Weight: "Weight",
    Value: "Value",
    ValuePerItem: "ValuePerItem",
    ItemKeywordTags: "ItemKeywordTags",
    ItemTypeTags: "ItemTypeTags",
    ItemColorTags: "ItemColorTags",
    Empty: "Empty",
});

const commonCsvToFieldType = {
    Name: FieldType.Name,
    Item: FieldType.Name,
    Resource: FieldType.Name,

    Department: FieldType.Department,

    Notes: FieldType.Description,
    Details: FieldType.Description,
    "Private Details": FieldType.Description,
    "Details (Private)": FieldType.Description,
    "Public Details": FieldType.DescriptionPublic,

    Source: FieldType.Source,

    Inventory: FieldType.Quantity,
    Inv: FieldType.Quantity,
    Quantity: FieldType.Quantity,
    Qty: FieldType.Quantity,
    Number: FieldType.Quantity,
    Num: FieldType.Quantity,

    Cost: FieldType.Cost,
    Price: FieldType.Cost,
    "Default Cost": FieldType.Cost,

    Type: FieldType.ItemTypeTags,
    Types: FieldType.ItemTypeTags,
    Keyword: FieldType.ItemKeywordTags,
    Keywords: FieldType.ItemKeywordTags,

    Color: FieldType.ItemColorTags,
    Colors: FieldType.ItemColorTags,

    Unit: FieldType.Unit,
    "Cost Type": FieldType.Unit,
    "Default Cost Type": FieldType.Unit,
    "Cost Unit": FieldType.Unit,
    "Default Cost Unit": FieldType.Unit,
    "Source Details": FieldType.SourceDetails,

    Size: FieldType.Size,
    Weight: FieldType.Weight,
    "Value per Item": FieldType.ValuePerItem,
    "Value": FieldType.ValuePerItem,
};

export const commonModelFields = {
    [FieldType.Name]: "Name",
    [FieldType.Department]: "DepartmentTag",
    [FieldType.Description]: "Description",
    [FieldType.DescriptionPublic]: "DescriptionPublic",
    [FieldType.Source]: "Source",
    [FieldType.SourceDetails]: "SourceDetails",
    [FieldType.Quantity]: "Quantity",
    [FieldType.Cost]: "Cost",
    [FieldType.Unit]: "Unit",
    [FieldType.Size]: "Size",
    [FieldType.Weight]: "Weight",
    [FieldType.Value]: "Value",
    [FieldType.ValuePerItem]: "ValuePerItem",
    [FieldType.ItemKeywordTags]: "ItemKeywordTags",
    [FieldType.ItemTypeTags]: "ItemTypeTags",
    [FieldType.ItemColorTags]: "ItemColorTags",
};

export const getCsvToFieldType = () => commonCsvToFieldType;

export const TimelineImportInputLabel = {
    [FieldType.Name]: "Item Name",
    [FieldType.Department]: "Department",
    [FieldType.Description]: "Details (Private)",
    [FieldType.DescriptionPublic]: "Details (Public)",
    [FieldType.Source]: "Source",
    [FieldType.SourceDetails]: "Source Details",
    [FieldType.Quantity]: "Inventory",
    [FieldType.Cost]: "Default Cost",
    [FieldType.Unit]: "Cost Type",
    [FieldType.Size]: "Size",
    [FieldType.Weight]: "Weight",
    [FieldType.Value]: "Value",
    [FieldType.ValuePerItem]: "Value per Item",
    [FieldType.ItemKeywordTags]: "Keywords",
    [FieldType.ItemTypeTags]: "Types",
    [FieldType.ItemColorTags]: "Colors",
};

const commonTypes = [
    FieldType.Name,
    FieldType.Department,
    FieldType.Description,
    FieldType.DescriptionPublic,
    FieldType.Source,
    FieldType.SourceDetails,
    FieldType.Quantity,
    FieldType.Cost,
    FieldType.Unit,
    FieldType.Size,
    FieldType.Weight,
    FieldType.Value,
    FieldType.ValuePerItem,
    FieldType.ItemKeywordTags,
    FieldType.ItemTypeTags,
    FieldType.ItemColorTags,
];

export const getTargetFieldTypes = () => commonTypes;

const toModelField = (data, type) => {
    let parsed = null;
    switch (type) {
        case FieldType.ItemKeywordTags:
        case FieldType.ItemTypeTags:
        case FieldType.ItemColorTags:
            parsed = data && data.length > 0 ? (data.includes(";") ? data.split(";").map(item => item.trim())
                : data.split(",").map(item => item.trim())) : [];
            break;
        default:
            parsed = data;
    }

    return parsed;
};

function parseUnit(unit) {
    switch (unit) {
        case "/Item":
        case "/Day":
        case "Flat":
            return unit;
        default:
            return "/Item";
    }
};

export const getParsedData = (dataList = [], columnsMap) => {
    const defaultFields = _.fromPairs(_.keys(commonModelFields).map((k) => [commonModelFields[k], toModelField(null, k)]));

    const plainItems = dataList.map(data => ({
        ...defaultFields,
        ..._.fromPairs(
            data.map((d, i) => {
                return [commonModelFields[columnsMap[i]], toModelField(d, columnsMap[i])];
            })
        ),
    }));

    const bySource = _.groupBy(plainItems, m => `${m?.Name}_${m?.DepartmentTag}_${m?.Description}`);

    const result = _.keys(bySource).filter(k => bySource[k] && bySource[k][0]).map(k => {
        const first = bySource[k][0];
        const sources = bySource[k].map((s, i) => {
            return {
                Key: i,
                Name: s.Source,
                Quantity: _.toFinite(s.Quantity) ? _.toInteger(s.Quantity) : null,
                Cost: _.toFinite(s.Cost) ? _.toNumber(s.Cost) : null,
                Unit: parseUnit(s.Unit),
                Description: s.SourceDetails,
                Size: s.Size,
                Weight: s.Weight,
                Value: s.Value,
                ValuePerItem: s.ValuePerItem,
                ItemKeywordTags: s.ItemKeywordTags,
                ItemTypeTags: s.ItemTypeTags,
                ItemColorTags: s.ItemColorTags,
            }
        });
        return {
            Name: first.Name,
            DepartmentTagId: first.DepartmentTag ? [first.DepartmentTag] : [],
            Description: first.Description,
            DescriptionPublic: first.DescriptionPublic,
            Sources: sources,
            Size: first.Size,
            Weight: first.Weight,
            ValuePerItem: first.ValuePerItem,
            ItemKeywordTags: first.ItemKeywordTags,
            ItemTypeTags: first.ItemTypeTags,
            ItemColorTags: first.ItemColorTags,
        }
    });

    return result;
}