import React from "react";
import {Form} from "antd";
import _ from "lodash";
import ImageUpload from "../../../Common/ImageUpload/ImageUpload";
import {InputNumberWithFormItem, InputWithFormItem, TextAreaWithFormItem} from "../../../Common/Input";
import {geTagsGroup} from "../../../../helpers/Tags";
import {formatInventory, formatToFixedFlex} from "../../../../helpers/text";
import AuditFormItem from "../../../Common/AuditFormItem";
import {useSelector} from "react-redux";
import {TagType, WeightUnit} from "../../../../constants/constants";
import TagsSelect from "../../../Common/Selects/TagsSelect";
import {moneyFormatter, moneyParser} from "../../../../helpers/money";
import "./InformationBlock.scss";

const InformationBlock = ({form, itemToEdit, save, isOptionalFieldDisabled, isReadOnly, activePortfolio}) => {
    const departments = useSelector((state) => state.tags.departments || []);
    const itemKeywords = useSelector((state) => state.tags.itemKeywords || []);
    const itemTypes = useSelector((state) => state.tags.itemTypes || []);
    const itemColors = useSelector((state) => state.tags.itemColors || []);
    const sources = itemToEdit?.Sources || [];
    const weightUnit = activePortfolio?.WeightUnitSettings?.Value === WeightUnit.Pounds ? "lbs." : "kg";

    const collections = itemToEdit?.Collections && _.join(_.sortBy(itemToEdit?.Collections.map((c) => c.Name), (c) => c.Id), ", ");

    const onChangeDepartment = () => {
        const list = form.getFieldValue("DepartmentTagId");
        const tag = list.pop();
        form.setFieldsValue({DepartmentTagId: tag ? parseInt(tag, 10) : null});

        save();
    };

    const onChangeImage = async (base64) => {
        const values = {ImageUrl: base64};
        const item = {...itemToEdit};
        if (values.ImageUrl && values.ImageUrl.indexOf("data:image") !== -1) {
            values.Photo = {
                IsChanged: true, Photo: values.ImageUrl.substr(values.ImageUrl.indexOf(",") + 1),
            };
            values.ImageUrl = null;
        } else if (item.ImageUrl && values.ImageUrl === null) {
            values.Photo = {
                IsChanged: true, Photo: null,
            };
            item.ImageUrl = null;
            item.Photo = {...values.Photo};
        }

        await form.setFieldsValue(values);
    };

    return (<>
        <InputWithFormItem
            name="Name"
            label={"Item"}
            hasFeedback
            rules={[{required: true, message: "Name"}]}
            onChanged={save}
            placeholder="Enter an item name"
            disabled={isReadOnly}
        />

        <TagsSelect
            tagType={TagType.Department}
            disabled={isOptionalFieldDisabled}
            label={"Department"}
            name={"DepartmentTagId"}
            placeholder="Select a department"
            save={onChangeDepartment}
            form={form}
            closeOnChange={true}
            getValueProps={(tagId) => ({value: tagId ? [tagId.toString()] : []})}
            groups={[geTagsGroup("All Departments", departments)]}
        />

        <TagsSelect
            tagType={TagType.ItemType}
            disabled={isOptionalFieldDisabled}
            label="Type"
            name="ItemTypeTags"
            placeholder="Select an item types"
            save={save}
            form={form}
            groups={[geTagsGroup("all item types", itemTypes)]}
        />

        <TagsSelect
            tagType={TagType.ItemKeyword}
            disabled={isOptionalFieldDisabled}
            label="Keywords"
            name="ItemKeywordTags"
            placeholder="Select an item keywords"
            save={save}
            form={form}
            groups={[geTagsGroup("all item keywords", itemKeywords)]}
        />

        <TagsSelect
            tagType={TagType.ItemColor}
            disabled={isOptionalFieldDisabled}
            label="Colors"
            name="ItemColorTags"
            placeholder="Select item colors"
            save={save}
            form={form}
            groups={[geTagsGroup("all item colors", itemColors)]}
        />

        <InputWithFormItem
            name="Size"
            label={"Size"}
            hasFeedback
            onChanged={save}
            placeholder="Enter an item size"
            disabled={isReadOnly}
        />

        <InputNumberWithFormItem
            disabled={isOptionalFieldDisabled}
            name="Weight"
            label="Weight"
            min={0}
            formatter={formatToFixedFlex}
            parser={(v) => parseFloat(v) && parseFloat(v).toFixed(2)}
            controls={false}
            onChanged={save}
            addonAfter={weightUnit}
            className="input-number-weight"
        />

        <InputNumberWithFormItem
            disabled={isOptionalFieldDisabled}
            name="ValuePerItem"
            label="Value per item"
            formatter={moneyFormatter}
            parser={moneyParser}
            controls={false}
            onChanged={save}
        />

        <TextAreaWithFormItem
            disabled={isOptionalFieldDisabled}
            name="Description"
            label={"Details (Private)"}
            onChanged={save}
            placeholder="Enter private details"
            useTextEditor={true}
        />

        <TextAreaWithFormItem
            disabled={isOptionalFieldDisabled}
            name="DescriptionPublic"
            label={"Details (Public)"}
            onChanged={save}
            placeholder="Enter public details"
            useTextEditor={true}
        />

        <Form.Item
            disabled={isOptionalFieldDisabled}
            hidden={isReadOnly}
            label={"Photo"}
            name="ImageUrl"
            className="ant-form-item-without-validation">
            <ImageUpload onChange={onChangeImage} onChanged={save}/>
        </Form.Item>

        <InputWithFormItem name="Photo" disabled={isReadOnly} type="hidden"/>

        <Form.Item hidden={isReadOnly} label={"Total Inventory"} className="ant-form-item-without-validation">
            {formatInventory(sources)}
        </Form.Item>
        <Form.Item hidden={!collections || isReadOnly} label={"Collections"}
                   className="ant-form-item-without-validation">
            {collections}
        </Form.Item>
        <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt" userProp="Creator"/>

        <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt" userProp="Updater"/>
    </>);
};

export default InformationBlock;