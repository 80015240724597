import React, {useEffect} from 'react';
import {Layout} from 'antd';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {pathCollection} from '../../helpers/paths';
import useActivePortfolio from '../../hooks/useActivePortfolio';
import {setSelected} from '../../redux/reducers/mainTable';
import usePermissions from '../../hooks/usePermissions';
import './CampaignAppLayout.scss';

const {Content} = Layout;

const CampaignAppLayout = ({children, route}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser.user);
    const isRequested = useSelector((state) => state.currentUser.isRequested);
    const {isFieldUser} = usePermissions();
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const isPortfolioLoading = useSelector((state) => state.projects.isPortfolioLoading);

    useActivePortfolio();

    useEffect(() => {
        dispatch(setSelected([]));
    }, [route, dispatch]);

    useEffect(() => {
        if (activePortfolioId && isPortfolioLoading) {
            if (!isFieldUser) {
                history.replace('/');
            } else if (currentUser === null && isRequested) {
                history.replace(pathCollection.logins.signin);
            }
        }
    }, [activePortfolioId, isPortfolioLoading]);

    return (!isPortfolioLoading && currentUser && isRequested ? (
            <Layout className="campaign-app-layout">
                <Content className="content">
                    {children}
                </Content>
            </Layout>
        ) : null
    );
};

export default CampaignAppLayout;