import _ from "lodash";
import moment from "moment"
import api from "../api";
import store from "../redux/store";
import {AccountType, ApiProjectType, ProjectPreset} from "../constants";
import {
    setAllPortfolioProjects,
    setMyPortfolios,
    setProjects,
    setActivePortfolio,
    setPortfolioLoading,
    setArchivedProjects,
    setActiveProjects,
    setActiveProjectsExtra
} from "../redux/reducers/projects";
import currencyObject from "../helpers/currencies.json";

const {dispatch} = store;

const setupPortfolios = (portfolios = []) => {
    return (portfolios || []).map(p => ({
        ...p, Currency: currencyObject[p.CurrencyCode || 'USD']
    }));
};

export const setupMomentLocale = (portfolio) => {
    const locale = moment.locale();
    moment.defineLocale(locale, {
        week: {
            dow: portfolio.DateTimeSettings.StartWeekOn.Value || 0,
        },
    });
};

export const loadPortfolios = async () => {
    try {
        dispatch(setPortfolioLoading(true));
        const myPortfolios = await api.post("api/Account/GetMyPortfolios");
        const myPortfoliosExtended = setupPortfolios(myPortfolios);

        const withOutPersonal = myPortfoliosExtended.filter(p => [AccountType.Group, AccountType.Admin].includes(p.Type) || p.HasSubscription);
        dispatch(setMyPortfolios(withOutPersonal));

        const activePortfolioId = store.getState().projects.activePortfolio?.Id;

        const activePortfolio = withOutPersonal.find(p => p.Id === activePortfolioId);
        if (activePortfolio) {
            dispatch(setActivePortfolio(activePortfolio));
            setupMomentLocale(activePortfolio);
        }    
        dispatch(setPortfolioLoading(false));
    } catch (error) {       
        dispatch(setPortfolioLoading(false));
    }
};

const projectDefaultFilter = {
    OrderBy: 1,
    IsDesc: true,
    Filter: {Keyword: "", States: [0, 1], OnlyFavorites: false, OnlyMyProjects: true, OnlySharedProjects: true}
}

export const getProjects = (accountId, states = [ApiProjectType.Active, ApiProjectType.Inactive], pageSize, includeLabourCostCalculation = false) => {

    return api.post(`api/Project/GetPaged?accountId=${accountId}`, {
        ...projectDefaultFilter,
        Filter: {
            ...projectDefaultFilter.Filter,
            States: states,
            IncludeLabourCostCalculation: includeLabourCostCalculation
        },
        Take: pageSize
    });
};

export const getProject = (id) => api.get(`api/Project/Get?id=${id}&checkIsAuthenticatedUserIsOwnerOfTheDeliverabledProject=true`, `Project_Get_${id}`);

export const createProject = (project) => api.post("api/Project/Create", project)

export const updateProject = (project) => api.post("api/Project/Update", project, `Project_Update_${project.Id}`)


const sortProjectsByDate = (p) => p.ProjectDate === null ? -1 : new Date(p.ProjectDate).getTime()
const sortProjectsByName = (p) => p.Name.toLowerCase()

const sortProjects = (projects) => _.orderBy(projects, [sortProjectsByDate, sortProjectsByName], ["desc", "asc"])

export const refreshCurrentUserPortfolioProjects = (includeLabourCostCalculation) => {
    const userPortfolioId = store.getState().currentUser?.user?.Id;
    const activePortfolioId = store.getState().projects.activePortfolio?.Id || null;
    const activeProjects = store.getState().projects.activeProjects;

    return getProjects(userPortfolioId, [ApiProjectType.Active, ApiProjectType.Inactive], undefined, includeLabourCostCalculation)
        .then(response => {
            const items = response.Items
                .filter(p => activePortfolioId === null || p.AccountId === activePortfolioId);

            const sortedItems = sortProjects(items);

            dispatch(setProjects(sortedItems));

            const updatedActiveProjects = activeProjects.filter(ap => items.some(item => item.Id === ap.Id));

            const unarchivedProjects = items.filter(item => !activeProjects.some(ap => ap.Id === item.Id));

            const finalActiveProjects = [...updatedActiveProjects, ...unarchivedProjects];

            if (finalActiveProjects.length === 0) {
                dispatch(setActiveProjects(sortedItems));
            } else {
                dispatch(setActiveProjects(finalActiveProjects));
            }

            dispatch(setActiveProjectsExtra([ProjectPreset.All, ProjectPreset.None]));

            return sortedItems;
        });
}

export const refreshArchivedProjects = (includeLabourCostCalculation) => {
    const userPortfolioId = store.getState().currentUser?.user?.Id
    const activePortfolioId = store.getState().projects.activePortfolio?.Id || null;

    return getProjects(userPortfolioId, [ApiProjectType.Archive], 100, includeLabourCostCalculation)
        .then(response => {
            const items = response.Items
                .filter(p => activePortfolioId === null || p.AccountId === activePortfolioId)

            dispatch(setArchivedProjects(sortProjects(items)));
            return items;
        })
}


export const refreshAllPortfolioProjects = () => {
    const activePortfolioId = store.getState().projects.activePortfolio.Id;

    return getProjects(activePortfolioId).then(response => {
        dispatch(setAllPortfolioProjects(response.Items));
        return response.Items;
    });
}

export const batchUpdate = (contactIds, categoriesData, stateDate, colorDate = {}, timeZoneDate = {}) => {
    const payload = {
        Versions: contactIds.map(id => ({Id: id})),

        UpdateCategories: categoriesData.isUpdated,
        UpdateOnlyCommonCategories: categoriesData.updateOnlyCommon,
        Categories: categoriesData.tags,

        UpdateState: stateDate.isUpdated,
        ProjectState: stateDate.value,

        UpdateColor: colorDate.isUpdated,
        Color: colorDate.value,

        UpdateTimeZoneId: timeZoneDate.isUpdated,
        TimeZoneId: timeZoneDate.value,
    };

    return api.post(`api/Project/BatchUpdate`, payload)
        .then(() => Promise.all([refreshCurrentUserPortfolioProjects(), refreshAllPortfolioProjects(),]))
}

export const getProjectTimetable = (projectId, withDateTimeOnly) => api.post(`api/ProjectTask/GetPaged?projectId=${projectId}`, {WithDateTimeOnly: withDateTimeOnly})

export const clone = (payload) => api.post("/api/Project/Clone", payload)

export const batchRemove = (ids = []) => api.post("/api/Project/BatchRemove", ids.map(id => ({Id: id})))

export const getRecentlyUpdatedData = () => api.get("/api/Statistic/GetMyRecentlyUpdatedData")

export const updatePortfolioPreferences = (payload) => api.post(`api/Account/UpdatePreferences`, payload)
