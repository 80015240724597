import React, {useRef, useState} from 'react';
import {Drawer, Typography, Button} from 'antd';
import Webcam from 'react-webcam';
import './TakePhoto.scss';
import {SyncOutlined} from "@ant-design/icons";

const {Text} = Typography;

const TakePhoto = ({visible, onClose, onCapture}) => {
    const webcamRef = useRef(null);
    const [facingMode, setFacingMode] = useState('environment');

    const handleCapture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        onCapture(imageSrc);
    };

    const toggleFacingMode = () => {
        setFacingMode((prevMode) => (prevMode === 'environment' ? 'user' : 'environment'));
    };

    return (<Drawer
        visible={visible}
        onClose={onClose}
        width="100%"
        className="take-photo-page"
        bodyStyle={{padding: 0, overflow: 'hidden'}}
        closable={false}
    >
        <div className="webcam-container">
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                className="webcam"
                videoConstraints={{facingMode}}
            />
        </div>
        <div className="modal-footer">
            <Text className="cancel-text" onClick={onClose}>
                Cancel
            </Text>
            <div className="capture-button-wrapper">
                <div className="capture-button" onClick={handleCapture}></div>
            </div>
            <Button
                className="switch-camera-button"
                onClick={toggleFacingMode}
                shape="circle"
                icon={<SyncOutlined className="switch-camera-icon"/>}
            />
            {/*<Text className="photos-text">Photos</Text>*/}
        </div>
    </Drawer>);
};

export default TakePhoto;