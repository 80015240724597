import React, {useState, useEffect} from "react";
import {Button} from "antd";
import {Sketch} from "@uiw/react-color";
import "./ColorPicker.scss";

const ColorPicker = ({initialColor, onColorChange}) => {
    const [colorHash, setColorHash] = useState(initialColor);
    const [isColorPickerVisible, setColorPickerVisible] = useState(false);

    useEffect(() => {
        setColorHash(initialColor);
    }, [initialColor]);

    const toggleColorPicker = () => {
        setColorPickerVisible(!isColorPickerVisible);
    };

    const handleColorChange = (color) => {
        setColorHash(color.hex);
        onColorChange(color.hex);
    };

    return (
        <>
            <Button className="color-picker-button" style={{backgroundColor: colorHash}} onClick={toggleColorPicker}>
                &nbsp;
            </Button>
            {isColorPickerVisible && (
                <Sketch
                    color={colorHash}
                    onChange={handleColorChange}
                    disableAlpha={true}
                />
            )}
        </>
    );
};

export default ColorPicker;