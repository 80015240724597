import React from 'react';
import {Col, Row, Button} from 'antd';
import {QrcodeOutlined, FilterOutlined, LogoutOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {deleteCurrentUser} from "../../../redux/reducers/auth";
import {pathCollection} from "../../../helpers/paths";
import './AppHeader.scss';

const AppHeader = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser.user);

    const handleLogout = () => {
        if (currentUser) {
            dispatch(deleteCurrentUser(null));
        }
        history.push(pathCollection.logins.signin);
    };

    return (
        <Row justify="space-between" align="middle">
            <Col>
                <img
                    src="https://app.propared.com/assets/Propared_LMWM_Square_Pond_85px.png"
                    alt="React Logo" height="60px"/>
            </Col>
            <Col>
                <div className="header-inventory-title">Inventory</div>
            </Col>
            <Col>
                <Button icon={<QrcodeOutlined/>} className="header-main-action-button"/>
                <Button icon={<FilterOutlined/>} className="header-main-action-button"/>
                <Button icon={<LogoutOutlined/>} className="header-main-action-button"
                        onClick={handleLogout}/>
            </Col>
        </Row>
    );
};

export default AppHeader;