/*
Contains paths for main application routes
*/
import {
    ContactType, ProjectType, RequirementType, ResourceType, SettingsType, SidePanelItemType, TeamContactType
} from "../constants";

export const contactsPath = {
    [ContactType.Person]: "/contacts/people",
    [ContactType.Organization]: "/contacts/organizations",
    [ContactType.Location]: "/contacts/locations",
};

export const resourcesPath = {
    [ResourceType.Item]: "/resources/items",
    [ResourceType.Collection]: "/resources/collections",
    [ResourceType.Usage]: "/resources/usage",
    [ResourceType.Position]: "/resources/positions",
};

export const requirementsPath = {
    [RequirementType.Item]: "/requirements/items",
    [RequirementType.Labour]: "/requirements/labour",
};


export const settingsPath = {
    [SettingsType.Access]: "/settings/access",
    [SettingsType.Preferences]: "/settings/preferences",
    [SettingsType.Tags]: "/settings/tags",
    [SettingsType.Subscription]: "/settings/subscription",
};

export const teamPath = {
    [TeamContactType.Person]: "/team/people",
    [TeamContactType.Organization]: "/team/organizations",
    [TeamContactType.Location]: "/team/locations",
};

export const projectsPath = {
    [ProjectType.Active]: "/projects/active", [ProjectType.Archive]: "/projects/archive",
};

export const timelinePath = {
    [SidePanelItemType.Event]: "/timeline/events",
    [SidePanelItemType.Booking]: "/timeline/crew",
    [SidePanelItemType.Notes]: "/timeline/notes",
};

export const campaignPath = {
    main: "/campaign",
    resourceEdit: "/campaign/resource/edit/:id",
};

export const logins = {
    signin: "/signin",
    forgotPassword: "/reset-password/:key",
    forgotPasswordRequest: "/reset-password",
    createAccount: "/signup",
    startTrialNew: "/start-trial-new-user",
    startTrialExisting: "/start-trial-current-user",
    instructorLicenseNewUser: "/instructor-license-new-user",
    instructorLicenseCurrentUser: "/instructor-license-current-user",
    instructorDashboard: "/instructor-dashboard",
    studentAccountNewUser: "/student-account-new-user/:linkHash",
    studentAccountCurrentUser: "/student-account-current-user/:linkHash",
};

export const pathCollection = {
    contactsPath,
    resourcesPath,
    teamPath,
    timelinePath,
    settingsPath,
    logins,
    projectsPath,
    attachments: "/attachments",
    requirementsPath,
    productionBooks: "/production-books",
    reports: "/reports",
    campaignPath,
};
