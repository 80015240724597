import React from 'react';
import {Button, Modal} from 'antd';
import './CodeIsUnassignedModal.scss';

const CodeIsUnassignedModal = ({isModalVisible, setIsModalVisible, handleCreateNewItem}) => (
    <Modal
        title={null}
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        className="code-is-unassigned-modal"
        closable={false}
    >
        <div className="code-is-unassigned-modal-text">This code is unassigned. What would you like to do?</div>
        <div className="code-is-unassigned-modal-content">
            <div className="code-is-unassigned-modal-gray-line"></div>
            <Button type="link" onClick={handleCreateNewItem}
                    className="code-is-unassigned-modal-button modal-text-size">
                Create a New Item
            </Button>
            <div className="code-is-unassigned-modal-gray-line"></div>
            <Button type="link" className="code-is-unassigned-modal-button modal-text-size">
                Link this code to an existing Item
            </Button>
            <div className="code-is-unassigned-modal-gray-line"></div>
            <Button type="text" danger onClick={() => setIsModalVisible(false)}
                    className="code-is-unassigned-modal-button modal-text-size">
                Cancel
            </Button>
        </div>
    </Modal>
);

export default CodeIsUnassignedModal;