import React from "react";
import {Route} from "react-router-dom";
import {
    AreaChartOutlined,
    BuildFilled,
    BulbFilled,
    BulbOutlined,
    CalendarOutlined,
    ClockCircleFilled,
    ContactsFilled,
    ContainerOutlined,
    DeploymentUnitOutlined,
    DollarCircleOutlined,
    EnvironmentOutlined,
    FileTextFilled,
    FolderOpenFilled,
    GroupOutlined,
    HomeFilled,
    LockOutlined,
    PaperClipOutlined,
    SearchOutlined,
    SendOutlined,
    SettingFilled,
    ShopOutlined,
    TagsFilled,
    TeamOutlined,
    ToolFilled,
    ToolOutlined,
    UnorderedListOutlined,
    UserOutlined,
} from "@ant-design/icons";

import Contacts from "../components/Contacts";
import Projects from "../components/Projects";
import Team from "../components/Team";
import Timeline from "../components/Timeline";
import Reports from "../components/Reports";
import Home from "../components/Home";
import InstructorDashboard from "../components/InstructorDashboard";
import Settings from "../components/Settings/Settings";
import {
    ContactType,
    PageLayout,
    ProjectType,
    RequirementType,
    ResourceType,
    SettingsType,
    SidePanelItemType,
    RegistrationType,
    TeamContactType,
    TimeLineTab,
} from "../constants";
import ContactsTitleRowButtons from "../components/Contacts/TitleRowButtons/ContactsTitleRowButtons";
import ProjectsTitleRowButtons from "../components/Projects/TitleRowButtons/ProjectsTitleRowButtons";
import TimelineTitleRowButtons from "../components/Timeline/TitleRowButtons";
import AttachmentTitleRowButtons from "../components/Attachments/TitleRowButtons";
import ProductionBooksTitleRowButtons from "../components/ProductionBooks/TitleRowButtons";
import ReportsTitleRowButtons from "../components/Reports/TitleRowButtons";
import SettingsTitleRowButtons from "../components/Settings/TitleRowButtons";
import SettingsTagsTitleRowButtons from "../components/Settings/Tags/TitleRowButtons";
import SettingsHeaderSecondRow from "../components/Settings/SettingsHeaderSecondRow";
import ResourcesTitleRowButtons from "../components/Resources/TitleRowButtons";
import RequirementsTitleRowButtons from "../components/Requirements/TitleRowButtons";

import MonthSelector from "../components/Common/MonthSelector";
import ProjectsMultipleSelector from "../components/Common/ProjectsMultipleSelector";
import ProjectsMultipleForAttachments
    from "../components/Attachments/FilterBar/ProjectsMultiple/AttachmentsProjectMultipleSelector";
import ProjectsMultipleForProductionBooks
    from "../components/ProductionBooks/FilterBar/ProjectsMultiple/ProductionBooksProjectMultipleSelector";
import TeamTitleRowButtons from "../components/Team/TitleRowButtons/TeamTitleRowButtons";
import HomeTitleRowButtons from "../components/Home/TitleRowButtons";
import ProjectSelector from "../components/Common/ProjectSelector";
import Attachments from "../components/Attachments/Attachments";
import ProductionBooks from "../components/ProductionBooks";
import Login from "../components/Public/Signin";
import ResetPassword from "../components/Public/ResetPassword";
import ResetPasswordRequest from "../components/Public/ResetPasswordRequest";
import BackendLayout from "../layouts/Backend";
import FrontendLayout from "../layouts/Frontend";
import CreateAccount from "../components/Public/CreateAccount";
import PortfolioName from "../components/Home/PortfolioName/PortfolioName";
import Resources from "../components/Resources";
import Requirements from "../components/Requirements";

import {
    campaignPath,
    contactsPath,
    logins,
    pathCollection,
    projectsPath,
    requirementsPath,
    resourcesPath,
    settingsPath,
    teamPath,
    timelinePath,
} from "./paths";
import NoActivePortfolioSecondRow from "../components/Settings/SettingsHeaderSecondRow/NoActivePortfolioSecondRow";
import TagTypeFilter from "../components/Settings/Tags/FilterBar/TagTypeFilter/TagTypeFilter";
import InstructorLayout from "../layouts/InstructorLayout/InstructorLayout";
import CreateAccountWrapper from "../components/Public/CreateAccountWrapper";
import CampaignHome from "../components/CampaignAppMain/Home/Home";
import CampaignAppLayout from "../layouts/CampaignAppLayout/CampaignAppLayout";
import ResourceEdit from "../components/CampaignAppMain/ResourceEdit/ResourceEdit";

const createBreadcrumb = (content, icon) => ({content, icon});

const routes = [{
    path: "/",
    exact: true,
    main: () => <Home/>,
    icon: () => <HomeFilled/>,
    title: "Home",
    layout: PageLayout.Backend,
    headerSecondRow: NoActivePortfolioSecondRow,
    titleButtons: () => <HomeTitleRowButtons/>,
    breadcrumbs: [createBreadcrumb("Home", HomeFilled), createBreadcrumb(PortfolioName, ContainerOutlined)],
}, {
    path: campaignPath.main,
    exact: true,
    hideButton: true,
    main: () => <CampaignHome/>,
    icon: () => <HomeFilled/>,
    title: "Campaign",
    layout: PageLayout.CampaignAppLayout,
    headerSecondRow: NoActivePortfolioSecondRow,
    childrenRoutes: [{
        exact: true,
        path: campaignPath.resourceEdit,
        main: () => <ResourceEdit/>,
        icon: () => <HomeFilled/>,
        title: "Resources Edit",
        breadcrumbs: [createBreadcrumb("Campaign", HomeFilled), createBreadcrumb(PortfolioName, ContainerOutlined)],
        layout: PageLayout.CampaignAppLayout,
    }],
},
    {
        path: logins.instructorDashboard,
        exact: true,
        hideButton: true,
        main: () => <InstructorDashboard/>,
        icon: () => <HomeFilled/>,
        title: "Instructor Dashboard",
        layout: PageLayout.InstructorLayout,
        headerSecondRow: NoActivePortfolioSecondRow,
        titleButtons: () => <HomeTitleRowButtons/>,
        breadcrumbs: [createBreadcrumb("Instructor Dashboard", HomeFilled), createBreadcrumb(PortfolioName, ContainerOutlined)],
    }, {
        path: contactsPath[ContactType.Person],
        main: () => <Contacts activeKey={ContactType.Person}/>,
        icon: () => <ContactsFilled/>,
        titleButtons: () => <ContactsTitleRowButtons contactType={ContactType.Person}/>,
        title: "Contacts",
        layout: PageLayout.Backend,
        childrenRoutes: [{
            path: contactsPath[ContactType.Person],
            main: () => <Contacts activeKey={ContactType.Person}/>,
            icon: () => <TeamOutlined/>,
            titleButtons: () => <ContactsTitleRowButtons contactType={ContactType.Person}/>,
            title: "People",
            breadcrumbs: [createBreadcrumb("CONTACTS", ContactsFilled), createBreadcrumb("People", UserOutlined)],
            layout: PageLayout.Backend,
        }, {
            path: contactsPath[ContactType.Organization],
            main: () => <Contacts activeKey={ContactType.Organization}/>,
            icon: () => <ShopOutlined/>,
            titleButtons: () => <ContactsTitleRowButtons contactType={ContactType.Organization}/>,
            title: "Organizations",
            breadcrumbs: [createBreadcrumb("CONTACTS", ContactsFilled), createBreadcrumb("Organizations", ShopOutlined)],
            layout: PageLayout.Backend,
        }, {
            path: contactsPath[ContactType.Location],
            main: () => <Contacts activeKey={ContactType.Location}/>,
            icon: () => <EnvironmentOutlined/>,
            titleButtons: () => <ContactsTitleRowButtons contactType={ContactType.Location}/>,
            title: "Locations",
            breadcrumbs: [createBreadcrumb("CONTACTS", ContactsFilled), createBreadcrumb("Locations", EnvironmentOutlined)],
            layout: PageLayout.Backend,
        },],
    }, {
        path: resourcesPath[ResourceType.Item],
        main: () => <Resources activeKey={ResourceType.Item}/>,
        icon: () => <DeploymentUnitOutlined/>,
        titleButtons: () => <ResourcesTitleRowButtons resourceType={ResourceType.Item}/>,
        title: "Resources",
        layout: PageLayout.Backend,
        childrenRoutes: [{
            path: resourcesPath[ResourceType.Item],
            main: () => <Resources activeKey={ResourceType.Item}/>,
            icon: () => <BulbOutlined/>,
            titleButtons: () => <ResourcesTitleRowButtons resourceType={ResourceType.Item}/>,
            title: "Items",
            breadcrumbs: [createBreadcrumb("RESOURCES", DeploymentUnitOutlined), createBreadcrumb("Items", BulbOutlined)],
            layout: PageLayout.Backend,
        }, {
            path: resourcesPath[ResourceType.Collection],
            main: () => <Resources activeKey={ResourceType.Collection}/>,
            icon: () => <GroupOutlined/>,
            titleButtons: () => <ResourcesTitleRowButtons resourceType={ResourceType.Collection}/>,
            title: "Collections",
            breadcrumbs: [createBreadcrumb("RESOURCES", DeploymentUnitOutlined), createBreadcrumb("Collections", GroupOutlined)],
            layout: PageLayout.Backend,
        }, {
            path: resourcesPath[ResourceType.Usage],
            main: () => <Resources activeKey={ResourceType.Usage}/>,
            icon: () => <AreaChartOutlined/>,
            titleButtons: () => <ResourcesTitleRowButtons resourceType={ResourceType.Usage}/>,
            title: "Usage",
            breadcrumbs: [createBreadcrumb("RESOURCES", DeploymentUnitOutlined), createBreadcrumb("Usage", AreaChartOutlined), createBreadcrumb(MonthSelector, FolderOpenFilled),],
            layout: PageLayout.Backend,
        }, {
            path: resourcesPath[ResourceType.Position],
            main: () => <Resources activeKey={ResourceType.Position}/>,
            icon: () => <ToolOutlined/>,
            titleButtons: () => <ResourcesTitleRowButtons resourceType={ResourceType.Position}/>,
            title: "Positions",
            breadcrumbs: [createBreadcrumb("RESOURCES", DeploymentUnitOutlined), createBreadcrumb("Positions", ToolOutlined)],
            layout: PageLayout.Backend,
        },],
    }, {
        path: projectsPath[ProjectType.Active],
        main: () => <Projects activeKey={ProjectType.Active}/>,
        icon: () => <FolderOpenFilled/>,
        titleButtons: () => <ProjectsTitleRowButtons projectType={ProjectType.Active}/>,
        title: "Projects",
        layout: PageLayout.Backend,
        childrenRoutes: [{
            path: projectsPath[ProjectType.Active],
            main: () => <Projects activeKey={ProjectType.Active}/>,
            icon: () => <FolderOpenFilled/>,
            titleButtons: () => <ProjectsTitleRowButtons projectType={ProjectType.Active}/>,
            title: "Active",
            breadcrumbs: [createBreadcrumb("PROJECTS", FolderOpenFilled), createBreadcrumb("Active Projects", FolderOpenFilled)],
            layout: PageLayout.Backend,
        }, {
            path: projectsPath[ProjectType.Archive],
            main: () => <Projects activeKey={ProjectType.Archive}/>,
            icon: () => <LockOutlined/>,
            titleButtons: () => <ProjectsTitleRowButtons projectType={ProjectType.Archive}/>,
            title: "Archives",
            breadcrumbs: [createBreadcrumb("PROJECTS", FolderOpenFilled), createBreadcrumb("Archives", LockOutlined)],
            layout: PageLayout.Backend,
        },],
    }, {
        path: pathCollection.attachments,
        main: () => <Attachments/>,
        icon: () => <PaperClipOutlined/>,
        titleButtons: () => <AttachmentTitleRowButtons/>,
        title: "Attachments",
        breadcrumbs: [createBreadcrumb("ATTACHMENTS", PaperClipOutlined), createBreadcrumb(ProjectsMultipleForAttachments, FolderOpenFilled),],
        layout: PageLayout.Backend,
    }, {
        path: teamPath[TeamContactType.Person],
        main: () => <Team activeKey={TeamContactType.Person}/>,
        icon: () => <TeamOutlined/>,
        titleButtons: () => <TeamTitleRowButtons/>,
        title: "Team",
        layout: PageLayout.Backend,
        childrenRoutes: [{
            path: teamPath[TeamContactType.Person],
            main: () => <Team activeKey={TeamContactType.Person}/>,
            icon: () => <TeamOutlined/>,
            titleButtons: () => <TeamTitleRowButtons teamContactType={TeamContactType.Person}/>,
            title: "People",
            breadcrumbs: [createBreadcrumb("TEAM", TeamOutlined), createBreadcrumb(ProjectSelector, FolderOpenFilled), createBreadcrumb("People", UserOutlined),],
            layout: PageLayout.Backend,
        }, {
            path: teamPath[TeamContactType.Organization],
            main: () => <Team activeKey={TeamContactType.Organization}/>,
            icon: () => <ShopOutlined/>,
            titleButtons: () => <TeamTitleRowButtons teamContactType={TeamContactType.Organization}/>,
            title: "Organizations",
            breadcrumbs: [createBreadcrumb("TEAM", TeamOutlined), createBreadcrumb(ProjectSelector, FolderOpenFilled), createBreadcrumb("Organizations", ShopOutlined),],
            layout: PageLayout.Backend,
        }, {
            path: teamPath[TeamContactType.Location],
            main: () => <Team activeKey={TeamContactType.Location}/>,
            icon: () => <EnvironmentOutlined/>,
            titleButtons: () => <TeamTitleRowButtons teamContactType={TeamContactType.Location}/>,
            title: "Locations",
            breadcrumbs: [createBreadcrumb("TEAM", TeamOutlined), createBreadcrumb(ProjectSelector, FolderOpenFilled), createBreadcrumb("Locations", EnvironmentOutlined),],
            layout: PageLayout.Backend,
        },],
    }, {
        path: timelinePath[SidePanelItemType.Event],
        main: () => <Timeline activeKey={TimeLineTab.Events}/>,
        icon: () => <ClockCircleFilled/>,
        title: "Timeline",
        titleButtons: () => <TimelineTitleRowButtons type={SidePanelItemType.Event}/>,
        layout: PageLayout.Backend,

        childrenRoutes: [{
            path: timelinePath[SidePanelItemType.Event],
            main: () => <Timeline activeKey={TimeLineTab.Events}/>,
            icon: () => <ClockCircleFilled/>,
            titleButtons: () => <TimelineTitleRowButtons type={SidePanelItemType.Event}/>,
            title: "Events",
            breadcrumbs: [createBreadcrumb("TIMELINE", ClockCircleFilled), createBreadcrumb("Events", CalendarOutlined), createBreadcrumb(ProjectsMultipleSelector, FolderOpenFilled),],
            layout: PageLayout.Backend,
        }, {
            path: timelinePath[SidePanelItemType.Notes],
            main: () => <Timeline activeKey={TimeLineTab.Notes}/>,
            icon: () => <UnorderedListOutlined/>,
            titleButtons: () => <></>,
            title: "Notes",
            breadcrumbs: [createBreadcrumb("TIMELINE", ClockCircleFilled), createBreadcrumb("Notes", UnorderedListOutlined), createBreadcrumb(ProjectsMultipleSelector, FolderOpenFilled),],
            layout: PageLayout.Backend,
        }, {
            path: timelinePath[SidePanelItemType.Booking],
            main: () => <Timeline activeKey={TimeLineTab.Crew}/>,
            icon: () => <ClockCircleFilled/>,
            titleButtons: () => <TimelineTitleRowButtons type={SidePanelItemType.Booking}/>,
            title: "Crew Booking",
            breadcrumbs: [createBreadcrumb("TIMELINE", ClockCircleFilled), createBreadcrumb("Crew Booking", ToolFilled), createBreadcrumb(ProjectsMultipleSelector, FolderOpenFilled),],
            layout: PageLayout.Backend,
        },],
    }, {
        path: requirementsPath[RequirementType.Item],
        main: () => <Requirements activeKey={RequirementType.Item}/>,
        icon: () => <BuildFilled/>,
        titleButtons: () => <RequirementsTitleRowButtons requirementType={RequirementType.Item}/>,
        title: "Requirements",
        layout: PageLayout.Backend,
        breadcrumbs: [createBreadcrumb("REQUIREMENTS", BuildFilled), createBreadcrumb(ProjectsMultipleSelector, FolderOpenFilled)],
        childrenRoutes: [{
            path: requirementsPath[RequirementType.Item],
            main: () => <Requirements activeKey={RequirementType.Item}/>,
            icon: () => <BulbFilled/>,
            titleButtons: () => <RequirementsTitleRowButtons requirementType={RequirementType.Item}/>,
            title: "Items",
            layout: PageLayout.Backend,
            breadcrumbs: [createBreadcrumb("REQUIREMENTS", BuildFilled), createBreadcrumb("Items", BulbFilled), createBreadcrumb(ProjectsMultipleSelector, FolderOpenFilled),],
        }, {
            path: requirementsPath[RequirementType.Labour],
            main: () => <Requirements activeKey={RequirementType.Labour}/>,
            icon: () => <BuildFilled/>,
            titleButtons: () => <RequirementsTitleRowButtons requirementType={RequirementType.Labour}/>,
            title: "Labour",
            layout: PageLayout.Backend,
            breadcrumbs: [createBreadcrumb("REQUIREMENTS", BuildFilled), createBreadcrumb("Labour", ToolOutlined), createBreadcrumb(ProjectsMultipleSelector, FolderOpenFilled),],
        },],
    }, {
        path: pathCollection.productionBooks,
        main: () => <ProductionBooks/>,
        icon: () => <SendOutlined/>,
        titleButtons: () => <ProductionBooksTitleRowButtons/>,
        title: "Production Books",
        breadcrumbs: [createBreadcrumb("PRODUCTION BOOKS", SendOutlined), createBreadcrumb(ProjectsMultipleForProductionBooks, FolderOpenFilled),],
        layout: PageLayout.Backend,
    }, {
        path: pathCollection.reports,
        main: () => <Reports/>,
        icon: () => <FileTextFilled/>,
        titleButtons: () => <ReportsTitleRowButtons/>,
        title: "Reports",
        breadcrumbs: [createBreadcrumb("REPORTS", FileTextFilled), createBreadcrumb(ProjectSelector, FolderOpenFilled)],
        layout: PageLayout.Backend,
    },

    {
        path: settingsPath[SettingsType.Access],
        main: () => <Settings/>,
        icon: () => <SettingFilled/>,
        title: "Settings",
        titleButtons: () => <SettingsTitleRowButtons/>,
        breadcrumbs: [createBreadcrumb("SETTINGS", SettingFilled), createBreadcrumb("Access", SearchOutlined)],
        layout: PageLayout.Backend,

        childrenRoutes: [{
            path: settingsPath[SettingsType.Access],
            main: () => <Settings type={SettingsType.Access}/>,
            icon: () => <SettingFilled/>,
            titleButtons: () => <SettingsTitleRowButtons/>,
            headerSecondRow: () => <SettingsHeaderSecondRow/>,
            title: "Access",
            breadcrumbs: [createBreadcrumb("SETTINGS", SettingFilled), createBreadcrumb("Access", SearchOutlined)],
            layout: PageLayout.Backend,
        }, {
            path: settingsPath[SettingsType.Preferences],
            main: () => <Settings type={SettingsType.Preferences}/>,
            icon: () => <SettingFilled/>,
            titleButtons: () => <></>,
            title: "Preferences",
            breadcrumbs: [createBreadcrumb("SETTINGS", SettingFilled), createBreadcrumb("Preferences", SettingFilled)],
            layout: PageLayout.Backend,
        }, {
            path: settingsPath[SettingsType.Tags],
            main: () => <Settings type={SettingsType.Tags}/>,
            icon: () => <TagsFilled/>,
            titleButtons: () => <SettingsTagsTitleRowButtons/>,
            title: "Tag Lists",
            breadcrumbs: [createBreadcrumb("SETTINGS", SettingFilled), createBreadcrumb("Tag Lists", TagsFilled), createBreadcrumb(TagTypeFilter, TagsFilled),],
            layout: PageLayout.Backend,
        }, {
            path: settingsPath[SettingsType.Subscription],
            main: () => <Settings type={SettingsType.Subscription}/>,
            icon: () => <DollarCircleOutlined/>,
            titleButtons: () => <></>,
            title: "Subscription",
            breadcrumbs: [createBreadcrumb("SETTINGS", SettingFilled), createBreadcrumb("Subscription", DollarCircleOutlined)],
            layout: PageLayout.Backend,
        },],
    },];

const selectForPath = (path) => routes.reduce((acc, current) => {
    if (current.childrenRoutes) {
        const children = current.childrenRoutes.find((childrenRoute) => childrenRoute.path === path);
        if (children) {
            return children;
        }
    }

    if (current.path === path) {
        return current;
    }

    return acc;
}, null);

const selectRootForPath = (path) => routes.reduce((acc, current) => {
    if (current.path === path) {
        return current;
    }
    if (current.childrenRoutes) {
        const children = current.childrenRoutes.find((childrenRoute) => childrenRoute.path === path);
        if (children) {
            return current;
        }
    }

    return acc;
}, null);

export const getRoutesWithChildren = (route) => {

    const element = (<Route
        path={route.path}
        exact={route.exact}
        children={() => {
            switch (route.layout) {
                case PageLayout.Frontend:
                    return (<FrontendLayout route={route}>
                        <route.main/>
                    </FrontendLayout>);

                case PageLayout.Backend:
                    return (<BackendLayout route={route}>
                        <route.main/>
                    </BackendLayout>);

                case PageLayout.InstructorLayout:
                    return (<InstructorLayout route={route}>
                        <route.main/>
                    </InstructorLayout>);

                case PageLayout.CampaignAppLayout:
                    return (<CampaignAppLayout route={route}>
                        <route.main/>
                    </CampaignAppLayout>);
                default:
                    return <route.main/>;
            }
        }}
    />);

    if (route.childrenRoutes) {
        return [element, ...route.childrenRoutes.map(getRoutesWithChildren)];
    }

    return [element];
};

const publicRoutes = [{
    path: logins.signin, exact: true, main: () => <Login></Login>, title: ["Sign In"], layout: PageLayout.Frontend,
}, {
    path: logins.forgotPasswordRequest,
    exact: true,
    main: () => <ResetPasswordRequest></ResetPasswordRequest>,
    title: ["Reset Your Password"],
    layout: PageLayout.Frontend,
}, {
    path: logins.forgotPassword,
    exact: true,
    main: () => <ResetPassword></ResetPassword>,
    title: ["Reset Your Password"],
    layout: PageLayout.Frontend,
}, {
    path: logins.createAccount,
    exact: true,
    main: () => <CreateAccount signInToAccountLink={logins.signin}></CreateAccount>,
    title: ["Create an Account"],
    layout: PageLayout.Frontend,
}, {
    path: logins.startTrialNew,
    exact: true,
    main: () => <CreateAccount withOrganization={true}
                               signInToAccountLink={logins.signin}
                               registrationType={RegistrationType.Owner}></CreateAccount>,
    title: ["Create Account & Start a Trial"],
    layout: PageLayout.Frontend,
}, {
    path: logins.startTrialExisting,
    exact: true,
    main: () => <CreateAccount withOrganization={true}
                               useExisting={true}
                               createNewAccountLink={logins.startTrialNew}
                               registrationType={RegistrationType.Owner}></CreateAccount>,
    title: ["Sign In & Start a Trial"],
    layout: PageLayout.Frontend,
}, {
    path: logins.instructorLicenseNewUser,
    exact: true,
    main: () => <CreateAccount withOrganization={true}
                               signInToAccountLink={logins.instructorLicenseCurrentUser}
                               registrationType={RegistrationType.Instructor}></CreateAccount>,
    title: ["Instructor License", "Create Account - New User"],
    layout: PageLayout.Frontend,
}, {
    path: logins.instructorLicenseCurrentUser,
    exact: true,
    main: () => <CreateAccount withOrganization={true}
                               useExisting={true}
                               createNewAccountLink={logins.instructorLicenseNewUser}
                               registrationType={RegistrationType.Instructor}></CreateAccount>,
    title: ["Instructor License", "Create Account - Current User"],
    layout: PageLayout.Frontend,
}, {
    path: logins.studentAccountNewUser, exact: true, main: () => <CreateAccountWrapper
        withOrganization={true}
        signInToAccountLink={logins.studentAccountCurrentUser}
        registrationType={RegistrationType.Student}
    ></CreateAccountWrapper>, title: ["Student Signup - New User"], layout: PageLayout.Frontend,
}, {
    path: logins.studentAccountCurrentUser,
    exact: true,
    main: () => <CreateAccountWrapper withOrganization={true}
                                      useExisting={true}
                                      buttonText="Sign In & Create Student Account"
                                      createNewAccountLink={logins.studentAccountNewUser}
                                      registrationType={RegistrationType.Student}/>,
    title: ["Student Signup - Current User"],
    layout: PageLayout.Frontend,
}];

export const getPublicRoutes = () => publicRoutes;

export const getRoutes = () => routes;
export const getRoute = (path) => selectForPath(path);
export const getRootRoute = (path) => selectRootForPath(path);
