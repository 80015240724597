import {debounce} from "lodash";
import React, {useState, useEffect} from "react";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        let isMounted = true;

        const handleResize = debounce(() => {
            if (isMounted) {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
        }, 300);

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            isMounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return windowSize;
}

export default useWindowSize;