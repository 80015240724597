import React, {useState, useEffect} from "react";
import {Modal, Form, message} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {TagType} from "../../../../constants/constants";
import {setTagToEdit} from "../../../../redux/reducers/tags";
import {updateTag} from "../../../../services/tags";
import {InputWithFormItem, SelectWithFormItem} from "../../../Common/Input";
import {iconOptions} from "../../../../helpers/Tags";
import ColorPicker from "../../../Common/ColorPicker/ColorPicker";
import {TagsFilled} from "@ant-design/icons";

const tagNames = {
    [TagType.Category]: "Category",
    [TagType.Department]: "Department",
    [TagType.Group]: "Group",
    [TagType.Role]: "Role",
    [TagType.TaskStatus]: "Task Status",
    [TagType.ItemKeyword]: "Item Keyword",
    [TagType.ItemType]: "Item Type",
    [TagType.ItemColor]: "Item Color",
};

const EditTag = ({tagType}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const tagToEdit = useSelector((state) => state.tags.tagToEdit);
    const [visible, setVisible] = useState(!!tagToEdit);
    const initialColor = form.getFieldValue("Icon");

    const tags = {
        [TagType.Category]: useSelector((state) => state.tags.categories),
        [TagType.Department]: useSelector((state) => state.tags.departments),
        [TagType.Group]: useSelector((state) => state.tags.groups),
        [TagType.Role]: useSelector((state) => state.tags.roles),
        [TagType.TaskStatus]: useSelector((state) => state.tags.statuses),
        [TagType.ItemKeyword]: useSelector((state) => state.tags.itemKeywords),
        [TagType.ItemType]: useSelector((state) => state.tags.itemTypes),
        [TagType.ItemColor]: useSelector((state) => state.tags.itemColors),
    };

    const items = tags[tagType] || [];

    useEffect(() => {
        if (tagToEdit) {
            form.setFieldsValue({Name: tagToEdit.Name, Icon: tagToEdit.Icon});
            setVisible(true);
        }
    }, [tagToEdit]);

    const handleOk = async () => {
        try {
            const finishedForm = await form.validateFields();
            setLoading(true);
            await updateTag({
                AccountTagId: tagToEdit.AccountTagId,
                Name: finishedForm.Name,
                Icon: finishedForm.Icon,
            });
            message.success(`${finishedForm.Name} was updated`);
            close();
        } catch (err) {
            message.error("Server error");
        } finally {
            setLoading(false);
        }
    };

    const close = () => {
        form.resetFields();
        dispatch(setTagToEdit(null));
        setVisible(false);
    };

    const handleColorChange = (color) => {
        form.setFieldsValue({Icon: color});
    };

    const onValuesChange = (changedValues) => {
        if (changedValues.Icon) {
            form.setFieldsValue({Icon: changedValues.Icon});
        }
    };

    if (!tagToEdit) return null;

    return (
        <Modal
            confirmLoading={isLoading}
            title={
                <>
                    <TagsFilled/> {tagToEdit.Name}
                </>
            }
            visible={visible}
            maskClosable={false}
            onOk={handleOk}
            onCancel={close}
            okText="Save"
        >
            <Form
                form={form}
                layout="horizontal"
                size="middle"
                onValuesChange={onValuesChange}
            >
                <InputWithFormItem
                    name="Name"
                    label={tagNames[tagType]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: `Please input your ${tagNames[tagType].toLowerCase()}`,
                        },
                        {
                            validator: (_, value) =>
                                !items.find(
                                    (i) =>
                                        i.AccountTagId !== tagToEdit.AccountTagId &&
                                        i.Name.toLowerCase() === (value || "").toLowerCase()
                                )
                                    ? Promise.resolve()
                                    : Promise.reject(
                                        new Error(
                                            `This ${tagNames[tagType].toLowerCase()} already exists.`
                                        )
                                    ),
                        },
                    ]}
                    placeholder="Enter a tag name"
                />
                {tagType === TagType.TaskStatus && (
                    <SelectWithFormItem
                        allowClear
                        label="Icon (optional)"
                        name="Icon"
                        style={{width: "100%"}}
                        placeholder="Select an icon"
                        options={iconOptions}
                    />
                )}
                {tagType === TagType.ItemColor && (
                    <Form.Item
                        label="Color"
                        name="Icon"
                        rules={[{required: true, message: "Please select a color"}]}
                    >
                        <ColorPicker
                            initialColor={initialColor}
                            onColorChange={handleColorChange}
                        />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

export default EditTag;