import React, {useState, useEffect} from 'react';
import {Button, Modal, Input, Spin, message} from 'antd';
import {useSelector} from 'react-redux';
import {createResource, createResourceCode, loadResources} from "../../../../services/resources";
import {errorFromHttpResponse} from "../../../../helpers/error";
import './NewResourceItemModal.scss';

const NewResourceItemModal = ({isNewResourceItemModalVisible, setIsNewResourceItemModalVisible, scanResult}) => {
    const [newItemName, setNewItemName] = useState("");
    const [loading, setLoading] = useState(false);
    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);

    useEffect(() => {
        if (isNewResourceItemModalVisible) {
            setNewItemName("");
        }
    }, [isNewResourceItemModalVisible]);

    const handleSaveNewResourceItem = async () => {
        setLoading(true);

        const payload = {
            Name: newItemName,
            AccountId: activePortfolioId,
        };

        try {
            const response = await createResource(payload);

            await createResourceCode({
                Text: scanResult.text,
                Format: scanResult.format,
                AccountId: activePortfolioId,
                ResourceId: response.Id,
            });

            loadResources(payload.AccountId);

            setIsNewResourceItemModalVisible(false);
        } catch (err) {
            const errorText = errorFromHttpResponse(err);
            message.error(errorText);

        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={null}
            visible={isNewResourceItemModalVisible}
            onCancel={() => setIsNewResourceItemModalVisible(false)}
            footer={null}
            closable={false}
            className="add-new-resource-modal"
        >
            <div className="add-new-resource-modal-text centered-text modal-text-size">
                Add an Item Name
            </div>
            <Spin spinning={loading}>
                <Input
                    placeholder="Type Item Name"
                    value={newItemName}
                    onChange={(e) => setNewItemName(e.target.value)}
                    className="full-width rounded-input padded-input large-font"
                />
            </Spin>
            <div className="add-new-resource-modal-content spaced-content">
                <Button type="text" onClick={() => setIsNewResourceItemModalVisible(false)} className="modal-text-size">
                    Cancel
                </Button>
                <Button type="link" onClick={handleSaveNewResourceItem} className="modal-text-size">
                    Save
                </Button>
            </div>
        </Modal>
    );
};

export default NewResourceItemModal;