import React, {useMemo, useRef, useState} from 'react';
import {Table, Image, Empty} from 'antd';
import {useVT} from 'virtualizedtableforantd4';
import {useHistory} from 'react-router-dom';
import './InventoryTable.scss';
import _ from 'lodash';
import useWindowSize from "../../../../hooks/useWindowSize";
import {infinity} from "../../../../helpers/text";
import ColorTag from "../../../Common/ColorTag/ColorTag";
import {campaignPath} from "../../../../helpers/paths";

const InventoryTable = ({items}) => {
    const size = useWindowSize();
    const tableRef = useRef();
    const paginationHeight = 80;
    const margin = 40;
    const pageSize = 50;
    const history = useHistory();

    const tableHeight = useMemo(() => {
        const viewportHeight = window.innerHeight;
        const offsetTop = tableRef.current?.getBoundingClientRect()?.top || 0;
        const hasPagination = items.length > pageSize;
        const extraSpace = hasPagination ? paginationHeight : 0;
        return viewportHeight - offsetTop - extraSpace - margin;
    }, [size.height, items.length]);

    const [vt] = useVT(() => ({scroll: {y: tableHeight, rowCount: items.length}}), [tableHeight, items]);

    const columns = useMemo(() => {
        if (size.width <= 768) {
            return [{
                title: 'Item',
                dataIndex: 'Name',
                key: 'Name',
                render: (text, record) => (<div className="item-container">
                    {record.ImageUrl ? (<img src={record.ImageUrl} alt={text} className="item-image"/>) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Image"/>)}
                    <div className="item-details">
                        <div className="item-property">{text}</div>
                        <div className="item-property">
                            {(() => {
                                const unlimited = record.Sources.filter((m) => !m.Quantity && m.Quantity !== 0);
                                const outOfStock = record.Sources.filter((m) => m.Quantity === 0);
                                const present = record.Sources.filter((m) => m.Quantity > 0);

                                if (present.length + outOfStock.length === 0 && unlimited.length > 0) return infinity;

                                return _.sum(record.Sources.map((m) => m.Quantity || 0));
                            })()}
                        </div>
                        <div className="item-property">{record.Sources.map((m) => m.Name).join(', ')}</div>
                        <div className="item-property">{record.DepartmentTag?.Name}</div>
                        <div className="item-property">
                            {(record.ItemTypeTags || []).map((x) => x.Name).join(', ')}
                        </div>
                        <div className="item-property">
                            {(record.ItemColorTags || []).map((x) => <ColorTag key={x.Name} color={x.Icon}
                                                                               text={x.Name}/>)}
                        </div>
                        <div className="item-property">
                            {(record.ItemKeywordTags || []).map((x) => x.Name).join(', ')}
                        </div>
                        <div className="item-property">{record.Size}</div>
                        <div className="item-property" dangerouslySetInnerHTML={{__html: record.Description}}/>
                    </div>
                </div>),
            },];
        } else {
            return [{
                title: '',
                dataIndex: 'ImageUrl',
                key: 'ImageUrl',
                className: 'resource-image',
                render: (ImageUrl, record) => (ImageUrl ? (<Image
                    src={ImageUrl}
                    width={50}
                    alt={record.name}
                />) : (<Empty
                    width={50}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Image"
                />)),
            }, {
                title: 'Item', dataIndex: 'Name', key: 'Name', render: (text) => <span data-label="Item">{text}</span>,
            }, {
                title: 'Inventory', dataIndex: 'Sources', key: 'Sources', render: (items = []) => {
                    const unlimited = items.filter((m) => !m.Quantity && m.Quantity !== 0);
                    const outOfStock = items.filter((m) => m.Quantity === 0);
                    const present = items.filter((m) => m.Quantity > 0);

                    if (present.length + outOfStock.length === 0 && unlimited.length > 0) return infinity;

                    return _.sum(items.map((m) => m.Quantity || 0));
                },
            }, {
                title: 'Sources',
                dataIndex: 'Sources',
                key: 'Sources',
                render: (items = []) => items.map((m) => m.Name).join(', '),
            }, {
                title: 'Department',
                dataIndex: 'DepartmentTag',
                key: 'DepartmentTag',
                render: (department) => department?.Name,
            }, {
                title: 'Type',
                dataIndex: 'ItemTypeTags',
                key: 'ItemTypeTags',
                render: (items) => (items || []).map((x) => x.Name).join(', '),
            }, {
                title: 'Color',
                dataIndex: 'ItemColorTags',
                key: 'ItemColorTags',
                render: (items) => (items || []).map((x) => <ColorTag key={x.Name} color={x.Icon} text={x.Name}/>),
            }, {
                title: 'Keywords',
                dataIndex: 'ItemKeywordTags',
                key: 'ItemKeywordTags',
                render: (items) => (items || []).map((x) => x.Name).join(', '),
            }, {
                title: 'Size', dataIndex: 'Size', key: 'Size',
            }, {
                title: 'Details (Private)',
                dataIndex: 'Description',
                key: 'Description',
                render: (data) => <div dangerouslySetInnerHTML={{__html: data}}/>,
            },];
        }
    }, [size.width]);

    const showPagination = items.length > pageSize;

    return (<div className="inventory-table-container">
        <Table
            ref={tableRef}
            dataSource={items}
            columns={columns}
            rowKey="Id"
            className="inventory-table"
            pagination={showPagination ? {pageSize} : false}
            components={vt}
            scroll={{y: tableHeight}}
            onRow={(record) => ({
                onClick: () => history.push(campaignPath.resourceEdit.replace(':id', record.Id)),
            })}
        />
    </div>);
};

export default InventoryTable;