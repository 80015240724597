import React from "react";
import "./ColorTag.scss";

const ColorTag = ({color, text, showBorder = false, width = "15px", height = "15px"}) => {
    return (
        <>
            {color && color.trim() ? (
                <div
                    className={`color-tag ${showBorder ? 'color-tag-border' : ''}`}
                    title={text}
                    aria-label={`Color tag: ${text}`}
                >
                    <div
                        className="color-tag-icon"
                        style={{backgroundColor: color, width, height}}
                        aria-hidden="true"
                    />
                    <span>{text}</span>
                </div>
            ) : (
                <span>{text}</span>
            )}
        </>
    );
};
export default ColorTag;