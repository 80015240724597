import React, {useState, useEffect, useRef, useCallback} from "react";
import {Collapse, Form, message} from "antd";
import {ContactsOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {useSelector, useDispatch} from "react-redux";

import {NewRecordState} from "../../../../constants/constants";
import {loadResources, createResource, updateResource} from "../../../../services/resources";
import {loadContacts} from "../../../../services/contacts";

import {continueEdit} from "../../../../redux/reducers/detailsPanel";
import {isRequestCanceled, errorFromHttpResponse} from "../../../../helpers/error";

import InformationBlock from "./InformationBlock";
import SourcesManagement from "../SourcesManagement/SourcesManagement";
import {sanitizeHTML} from "../../../../helpers/html";

const InformationHeader = (<>
    <InfoCircleOutlined/> Information
</>);
const SourcesHeader = (<>
    <ContactsOutlined/> Sources
</>);

const prepareFormData = (resource) => {

    const formData = {
        ...resource,
        ItemKeywordTags: resource.ItemKeywordTags?.map((t) => t.AccountTagId.toString()),
        ItemTypeTags: resource.ItemTypeTags?.map((t) => t.AccountTagId.toString()),
        ItemColorTags: resource.ItemColorTags?.map((t) => t.AccountTagId.toString()),
    };

    return formData;
};

const ResourceDetailsPanel = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const nextInputRef = useRef();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit;
    const allContacts = useSelector((state) => state.contacts.contacts);

    useEffect(() => {
        if (!activePortfolio) return;

        if (allContacts.length === 0) {
            loadContacts(activePortfolio.Id);
        }
    }, [activePortfolio]);

    useEffect(() => {
        if (!itemToEdit) return;

        setEditItemState(NewRecordState.ReadyForEdit);

        const formData = prepareFormData(itemToEdit);

        form.setFieldsValue(formData);
    }, [itemToEdit]);

    const prepareAndCreate = useCallback((finishedForm) => {
        const payload = {
            Name: finishedForm.Name, AccountId: activePortfolio.Id,
        };

        createResource(payload)
            .catch((err) => {
                const errorText = errorFromHttpResponse(err);

                message.error(errorText);
                throw new Error(errorText);
            })
            .then((response) => {
                const item = {Id: response.Id, Name: payload.Name};
                dispatch(continueEdit({item}));

                setEditItemState(NewRecordState.ReadyForEdit);

                loadResources(payload.AccountId);

                message.success(`${item.Name} was created`);
            })
            .catch(console.error);

        setEditItemState(NewRecordState.RequiredFieldsSubmitted);
    }, [itemToEdit?.Id, dispatch]);

    const prepareAndUpdate = useCallback((finishedForm) => {

        const updatePayload = {
            Id: itemToEdit.Id,
            Name: finishedForm.Name,
            Description: sanitizeHTML(finishedForm.Description),
            DescriptionPublic: sanitizeHTML(finishedForm.DescriptionPublic),
            Size: finishedForm.Size,
            ValuePerItem: finishedForm.ValuePerItem,
            Weight: finishedForm.Weight,
            DepartmentTagId: finishedForm.DepartmentTagId,
            Photo: finishedForm.Photo,
            ImageUrl: finishedForm.ImageUrl,
            ItemKeywordTags: finishedForm.ItemKeywordTags.map((t) => parseInt(t, 10)),
            ItemTypeTags: finishedForm.ItemTypeTags.map((t) => parseInt(t, 10)),
            ItemColorTags: finishedForm.ItemColorTags.map((t) => parseInt(t, 10)),
        };

        updateResource(updatePayload)
            .then((response) => {
                const item = {
                    ...itemToEdit, ...updatePayload,
                    ImageUrl: response.ImageUrl,
                    Version: response.Version,
                    ItemKeywordTags: response.ItemKeywordTags,
                    ItemTypeTags: response.ItemTypeTags,
                    ItemColorTags: response.ItemColorTags,
                };

                dispatch(continueEdit({item}));
                loadResources(activePortfolio.Id);
            })
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                }
            });
    }, [itemToEdit?.Id, dispatch]);

    const onFinish = useCallback((finishedForm) => {
        if (editItemState === NewRecordState.Empty) {
            prepareAndCreate(finishedForm);
        }
        if (editItemState === NewRecordState.ReadyForEdit) {
            prepareAndUpdate(finishedForm);
        }
    }, [editItemState, prepareAndCreate, prepareAndUpdate]);

    const save = useCallback(async () => {
        try {
            const values = isOptionalFieldDisabled ? await form.getFieldsValue() : await form.validateFields();

            if (!isOptionalFieldDisabled || values.Name) {
                setEditItemState(NewRecordState.ReadyForEdit);
                onFinish(values);
            }
        } catch (err) {
            console.error(err);
        }
    }, [form, onFinish]);

    return (<div className="side-panel with-collapse">
        <Collapse defaultActiveKey={["Information", "Sources"]}>
            <Collapse.Panel key="Information" header={InformationHeader}>
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                    <InformationBlock
                        form={form}
                        save={save}
                        firstInputRef={firstInputRef}
                        nextInputRef={nextInputRef}
                        itemToEdit={itemToEdit}
                        isOptionalFieldDisabled={isOptionalFieldDisabled}
                        activePortfolio={activePortfolio}
                    />
                </Form>
            </Collapse.Panel>
            <Collapse.Panel key="Sources" header={SourcesHeader} className="resource-management-panel">
                <SourcesManagement
                    resourceId={itemToEdit?.Id}
                    onChange={() => loadResources(activePortfolio?.Id)}
                    onDelete={() => loadResources(activePortfolio?.Id)}
                />
            </Collapse.Panel>
        </Collapse>
    </div>);
};

export default ResourceDetailsPanel;