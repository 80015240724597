import {useSelector} from "react-redux";
import _ from "lodash";
import {ApiPermissionLevel, ApiProjectType, MaxLimitOfProjectsForStudent} from "../constants";
import {useEffect, useState} from "react";

const usePermissions = () => {

    const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const projects = useSelector((state) => state.projects.projects);
    const activeProjects = useSelector((state) => state.projects.projects).filter((p) => p.State !== ApiProjectType.Archive);

    const isOwner = activePortfolio?.PermissionLevel === ApiPermissionLevel.Owner;
    const isAdmin = activePortfolio?.PermissionLevel === ApiPermissionLevel.Administrator;
    const isInstructor = activePortfolio?.PermissionLevel === ApiPermissionLevel.Instructor;
    const isStudent = activePortfolio?.PermissionLevel === ApiPermissionLevel.Student;
    const isProjectManager = activePortfolio?.PermissionLevel === ApiPermissionLevel.AssistantOrViewer;
    const isFieldUser = activePortfolio?.PermissionLevel === ApiPermissionLevel.FieldUser;

    const HasSubscription = activePortfolio?.HasSubscription;
    const hasThingsAccess = activePortfolio?.HasThingsAccess;
    const canStudentAddNewProject = isStudent && activeProjects && activeProjects.length < MaxLimitOfProjectsForStudent;

    const canEditProductionBook = (productionBook) => {
        return (isOwner || isStudent || isAdmin || (isProjectManager && !_.isNil(productionBook) && !productionBook.Schedule.IsAllProjects && _.intersection(productionBook.Schedule.Projects.map((p) => p.Id), projects.map((p) => p.Id)).length === productionBook.Schedule.Projects.length));
    };

    useEffect(() => {
        if (activePortfolio) {
            setIsPermissionsLoaded(true);
        }
    }, [activePortfolio]);

    return {
        isPermissionsLoaded,
        isOwner,
        isAdmin,
        isProjectManager,
        isInstructor,
        isStudent,
        isFieldUser,
        canManageProjects: ((isOwner || isStudent) && HasSubscription) || isAdmin,
        canManageProjectManagers: ((isOwner) && HasSubscription) || isAdmin,
        canCreateContacts: ((isOwner || isStudent) && HasSubscription) || isAdmin || isProjectManager,
        canCreateProductionBooks: ((isOwner || isStudent) && HasSubscription) || isAdmin || isProjectManager,
        canCreateReports: ((isOwner || isStudent) && HasSubscription) || isAdmin || isProjectManager,
        canCreateEvents: ((isOwner || isStudent) && HasSubscription) || isAdmin || isProjectManager,
        canCreateTeamMembers: ((isOwner || isStudent) && HasSubscription) || isAdmin || isProjectManager,
        canAccessSettings: ((isOwner || isStudent) && HasSubscription) || isAdmin,
        canStudentAddNewProject: canStudentAddNewProject,
        canAccessSubscription: isOwner || isStudent,
        hasThingsAccess: hasThingsAccess,
        canEditProductionBook,
    };
};

export default usePermissions;