import React, {useState} from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {completeQuickSignUp, validateAccountBeforeSignUp, whoAmI} from "../../services/auth";
import {NavLink, useHistory, useParams} from "react-router-dom";
import {AccountType, RegistrationType} from "../../constants";
import {validateResponseWithMessage} from "./PublicComponentHelpers";

const layout = {
    layout: "vertical",
};

const CreateAccount = ({
                           withOrganization = false,
                           useExisting = false,
                           registrationType,
                           createNewAccountLink,
                           signInToAccountLink,
                           buttonText = "Create Account & Sign In"
                       }) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const {linkHash} = useParams();

    const onFinish = async (values) => {
        try {
            setLoading(true);

            const accountTypeToCreate = withOrganization ? AccountType.Group : AccountType.User;

            const validationResponse = await validateAccountBeforeSignUp({
                ...values,
                ConfirmPassword: values.Password,
                AccountTypeToCreate: accountTypeToCreate,
                UseExisting: useExisting,
                RegistrationType: registrationType,
                LinkHash: linkHash,
            });

            if (!validateResponseWithMessage(validationResponse)) {
                return
            }

            const userResponse = await completeQuickSignUp({
                CouponCode: null, BillingInfoTokenId: null, Request: {
                    ...values,
                    ConfirmPassword: values.Password,
                    Company: values.Organization,
                    HowUserGotIntoSignUp: null,
                    UseExisting: useExisting,
                    AccountTypeToCreate: accountTypeToCreate,
                    RegistrationType: registrationType,
                    LinkHash: linkHash,
                }, IsCollaborator: true,
            });

            if (!validateResponseWithMessage(userResponse)) {
                return;
            }

            const whoAmIResponse = await whoAmI();
            if (!whoAmIResponse) {
                throw new Error("WhoAmI returns error");
            }

            history.push("/");
        } catch (err) {
            message.error(err.message || "Server error");
        } finally {
            setLoading(false);
        }
    };

    const onFinishFailed = () => {
    };

    return (<Form {...layout} name="basic" initialValues={{remember: true}} onFinish={onFinish}
                  onFinishFailed={onFinishFailed}>
        {useExisting && (<Form.Item>
            Don't have an account?{" "}
            <NavLink to={createNewAccountLink} exact={true} className="login_content_reset">
                Create One Here.
            </NavLink>
        </Form.Item>)}
        {!useExisting && (<Form.Item>
            Already have an account?{" "}
            <NavLink to={signInToAccountLink} exact={true} className="login_content_reset">
                Sign In Here.
            </NavLink>
        </Form.Item>)}
        {withOrganization && registrationType !== RegistrationType.Student && (<Form.Item
            label="Organization Name"
            name="Organization"
            rules={[{required: true, message: "Please input your  Organization!"}]}>
            <Input placeholder="Enter your Organization"/>
        </Form.Item>)}
        {!useExisting && (<>
            <Form.Item label="First Name" name="FirstName"
                       rules={[{required: true, message: "Please input your First Name!"}]}>
                <Input placeholder="Enter First Name"/>
            </Form.Item>
            <Form.Item label="Last Name" name="LastName"
                       rules={[{required: true, message: "Please input your Last Name!"}]}>
                <Input placeholder="Enter Last Name"/>
            </Form.Item>
        </>)}
        <Form.Item label="Email Address" name="Login"
                   rules={[{required: true, message: "Please input your  Email Address!"}]}>
            <Input placeholder="Enter Email Address"/>
        </Form.Item>
        <Form.Item
            label="Password (16 Character Minimum)"
            name="Password"
            rules={[{min: 16, message: "Password must contain at least 16 characters in length."}, {
                required: true, message: "Please input your password!"
            },]}>
            <Input.Password placeholder="Enter your Password"/>
        </Form.Item>
        <Form.Item
            valuePropName="checked"
            name="Agreed"
            rules={[{required: true, message: "Please agree to the Terms of Service and Privacy Policy"}]}>
            <Checkbox>
                I have read and agree to the{" "}
                <a href="https://www.propared.com/legal" className="login_content_reset" target="_blank"
                   rel="noopener noreferrer">
                    Terms of Service
                </a>{" "}
                and{" "}
                <a
                    href="https://www.propared.com/legal/#legal-2"
                    className="login_content_reset"
                    target="_blank"
                    rel="noopener noreferrer">
                    Privacy Policy
                </a>
            </Checkbox>
        </Form.Item>
        <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" size="large">
                {buttonText}
            </Button>
        </Form.Item>
    </Form>);
};

export default CreateAccount;
