import React, {useEffect} from "react";
import {Layout, Anchor} from "antd";
import "./Backend.scss";
import MainNavigation from "../../components/MainNavigation/MainNavigation";
import DetailsPanel from "../../components/DetailsPanel/DetailsPanel";
import TitleRow from "../../components/TitleRow/TitleRow";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {pathCollection} from "../../helpers/paths";
import useActivePortfolio from "../../hooks/useActivePortfolio";
import {setSelected} from "../../redux/reducers/mainTable";
import usePermissions from "../../hooks/usePermissions";
import PortfolioLoading from "../../components/PortfolioLoading/PortfolioLoading";

const {Header, Content} = Layout;

const BackendLayout = ({children, route}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isBulkSelection = useSelector((state) => state.mainTable.isBulkSelection);
    const currentUser = useSelector((state) => state.currentUser.user);
    const isRequested = useSelector((state) => state.currentUser.isRequested);
    const {isInstructor, isFieldUser} = usePermissions();

    const activePortfolioId = useSelector((state) => state.projects.activePortfolio?.Id);
    const isPortfolioLoading = useSelector((state) => state.projects.isPortfolioLoading);

    useActivePortfolio();

    useEffect(() => {
        dispatch(setSelected([]));
    }, [route, dispatch]);

    useEffect(() => {
        if (currentUser === null && isRequested) {
            history.replace(pathCollection.logins.signin);
        } else if (isFieldUser) {
            history.replace(pathCollection.campaignPath.main);
        } else if (isInstructor) {
            history.replace(pathCollection.logins.instructorDashboard);
        }
    }, [isFieldUser, isInstructor, currentUser, isRequested, history]);

    if (isPortfolioLoading) {
        return <PortfolioLoading/>;
    }

    return (!isPortfolioLoading && currentUser && isRequested && (<Layout style={{minHeight: "100vh"}}>
        <div className="left-layout-panel">
            <Anchor>
                <MainNavigation/>
            </Anchor>
        </div>
        <Layout className="main-content-layout">
            <Header className={{"batch-selection-header": isBulkSelection}}>
                <TitleRow/>
            </Header>
            <Content>{children}</Content>
            <DetailsPanel></DetailsPanel>
        </Layout>
    </Layout>));
};

export default BackendLayout;