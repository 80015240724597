export const API_BASE = "/";
//export const API_BASE = "https://localhost:7164";
export const DriftAppId = "w36efkxtv295";
export const SyncfusionLicenseKey = 'Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfdXVcRWJcV0J+WkE=';

export const MaxLimitOfProjectsForStudent = 1;

export const SidePanelItemType = Object.freeze({
    Person: "Person",
    Organization: "Organization",
    Location: "Location",
    Project: "Project",
    TeamPerson: "TeamPerson",
    TeamOrganization: "TeamOrganization",
    TeamLocation: "TeamLocation",
    TeamSpace: "TeamSpace",
    Event: "Event",
    Booking: "Booking",
    Notes: "Notes",
    Attachment: "Attachment",
    ProductionBooks: "ProductionBooks",
    Reports: "Reports",
    ReportsOld: "ReportsOld",
    ReportTemplate: "ReportTemplate",
    ContactsSearch: "ContactsSearch",
});

export const PageLayout = Object.freeze({
    Frontend: "Frontend",
    Backend: "Backend",
    InstructorLayout: "InstructorLayout",
    CampaignAppLayout: "CampaignAppLayout",
});

export const AccountType = Object.freeze({
    User: 0, Group: 1, Admin: 2
});

export const RegistrationType = Object.freeze({
    Owner: 0, Instructor: "1", Student: "2",
});

export const ContactType = Object.freeze({
    Person: "Person", Organization: "Organization", Location: "Location",
});

export const ScreenType = Object.freeze({
    Collections: "Collections", Requirements: "Requirements"
});

export const ResourceType = Object.freeze({
    Item: "Item", Collection: "Collection", Usage: "Usage", Position: "Position"
});

export const RequirementType = Object.freeze({
    Item: "Item_", Labour: "Labour"
});

export const SettingsType = Object.freeze({
    Access: "Access", Preferences: "Preferences", Tags: "Tags", Subscription: "Subscription",
});

export const TeamContactType = Object.freeze({
    Person: "TeamPerson", Organization: "TeamOrganization", Location: "TeamLocation",
});

export const ApiContactType = Object.freeze({
    Person: 0, Organization: 1, Place: 2, Space: 3,
});

export const TeamApiContactTypes = {
    [TeamContactType.Person]: ApiContactType.Person,
    [TeamContactType.Organization]: ApiContactType.Organization,
    [TeamContactType.Location]: ApiContactType.Place,
}

export const NewRecordState = Object.freeze({
    Empty: "Empty", RequiredFieldsSubmitted: "RequiredFieldsSubmitted", ReadyForEdit: "ReadyForEdit",
});

export const TagType = Object.freeze({
    Role: 0, Department: 1, Category: 2, Group: 3, TaskStatus: 4, ItemType: 5, ItemKeyword: 6, ItemColor: 7,
});

export const ProjectType = Object.freeze({
    Active: "Active", Inactive: "Draft", Archive: "Archive",
});

export const ApiProjectType = Object.freeze({
    Active: 1, Inactive: 0, Archive: 2,
})

export const TimeLineTab = Object.freeze({
    Events: "Events", Crew: "Crew", Notes: "Notes",
});

export const ApiTimelineItemType = Object.freeze({
    Task: 0, Booking: 3,
});

export const ApiTimelineItemStatus = Object.freeze({
    None: 0, Tentative: 1, Confirmed: 2,
});

export const ApiFilterLayoutType = Object.freeze({
    Spreadsheet: 0, GroupedByDay: 1, GroupedByLocation: 2, GroupedByProject: 3
})

export const ApiFilterLayout = Object.freeze({
    Calendar: 0, List: 1, Day: 2, Year: 3, Week: 4
})

export const ApiFilterIncludeType = Object.freeze({
    Any: 0, All: 1
});

export const ApiFilterCrewType = Object.freeze({
    LabourLines: 0, CrewBookings: 1
});

export const ApiFilterTaskType = Object.freeze({
    None: 0, Task: 1, FeedTask: 2, Workcall: 4, Booking: 8, All: 15
});

export const ApiPermissionLevel = Object.freeze({
    Owner: 0, Administrator: 1, AssistantOrViewer: 2, Instructor: 3, Student: 4, FieldUser: 5,
});

export const ApiPermissionLevelName = Object.freeze({
    [ApiPermissionLevel.Owner]: "Owner",
    [ApiPermissionLevel.Administrator]: "Administrator",
    [ApiPermissionLevel.AssistantOrViewer]: "Project Manager",
    [ApiPermissionLevel.Instructor]: "Instructor",
    [ApiPermissionLevel.Student]: "Student",
    [ApiPermissionLevel.FieldUser]: "Field User",
});

export const ApiTimelineItemStatusName = Object.freeze({
    [ApiTimelineItemStatus.None]: "None",
    [ApiTimelineItemStatus.Tentative]: "Tentative",
    [ApiTimelineItemStatus.Confirmed]: "Confirmed",
});

export const TaskBatchPropertyFlags = Object.freeze({
    Name: 1,
    Notes: 2,
    Date: 4,
    Time: 8,
    DepartmentTags: 16,
    CategoryTags: 32,
    Locations: 64,
    TeamMemberIds: 128,
    TeamMemberContactIds: 128,
    StatusTagId: 256, //RoleTags: 512,
});

export const BookingBatchPropertyFlags = Object.freeze({
    None: 0, PositionId: 1, BookingStatus: 2, Date: 4, Departments: 8, Notes: 16, TeamMemberContactIds: 32, Time: 64,
});

export const FilterColumnType = Object.freeze({
    Name: 0,
    Address: 1,
    Spaces: 2,
    Roles: 3,
    Email: 4,
    Phone: 5,
    Source: 6,
    Location: 7,
    Dates: 8,
    Details: 9,
    Cost: 10,
    Photos: 11,
    Organization: 12,
    Status: 13,
});

export const ApiDeliverableType = Object.freeze({
    ContactList: 0, Schedule: 1, Report: 2, CallSheet: 3,
});

export const ProjectPreset = Object.freeze({
    All: 'All', None: 'None',
});

export const ResourceUnitType = Object.freeze({
    Item: "Item", Day: "Day", Flat: "Flat"
});

export const LabourCostUnit = Object.freeze({
    Hour: 0, Flat: 1
});

export const ViewMode = Object.freeze({
    List: "List", Day: "Day", Week: "Week", Month: "Month",
});

export const ViewType = Object.freeze({
    Day: "Day", Week: "Week", Month: "Month",
});

export const dayOptions = [{value: 1, label: "Monday"}, {value: 2, label: "Tuesday"}, {
    value: 3, label: "Wednesday"
}, {value: 4, label: "Thursday"}, {value: 5, label: "Friday"}, {value: 6, label: "Saturday"}, {
    value: 7, label: "Sunday"
},];

export const WeightUnit = Object.freeze({
    Pounds: 1, Kilograms: 2
});

export const convertApiContactTypeToContactType = (apiContactType) => {
    const apiContactTypeMapping = {
        [ApiContactType.Person]: ContactType.Person,
        [ApiContactType.Organization]: ContactType.Organization,
        [ApiContactType.Place]: ContactType.Location,
    };

    return apiContactTypeMapping[apiContactType] || null;
};

export const UrlHosts = Object.freeze({
    Feeds: {
        DEV: "dev.propared.com",
        QA: "qa.propared.com",
        PRODUCTION: "feeds.propared.com",
        STAGING: "prefeeds.propared.com",
        LOCAL: "localhost"
    }, Pages: {
        DEV: "dev.propared.com",
        QA: "qa.propared.com",
        PRODUCTION: "pages.propared.com",
        STAGING: "prepages.propared.com",
        LOCAL: "localhost"
    }
});

export const environmentMap = {
    'dev.propared.com': 'DEV',
    'qa.propared.com': 'QA',
    'pre.propared.com': 'STAGING',
    'app.propared.com': 'PRODUCTION',
    'localhost': 'LOCAL'
};

export const getEnvironment = () => {
    const hostname = window.location.hostname;
    return environmentMap[hostname] || 'LOCAL';
};

const getHost = (type) => {
    const environment = getEnvironment();
    if (environment === 'LOCAL') {
        return `${window.location.hostname}:${window.location.port}`;
    }
    return UrlHosts[type][environment];
};

export const getPagesHost = () => getHost('Pages');
export const getFeedsHost = () => getHost('Feeds');