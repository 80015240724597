import React, {useEffect, useRef, useState} from "react";
import _ from "lodash";
import {Checkbox, Col, Collapse, Form, message, Row, Switch} from "antd";
import {
    ApiFilterLayout, ApiFilterLayoutType, getFeedsHost, getPagesHost, NewRecordState,
} from "../../../constants/constants";
import {continueEdit} from "../../../redux/reducers/detailsPanel";
import {errorFromHttpResponse, isRequestCanceled} from "../../../helpers/error";
import {InputWithFormItemWithRef, TextAreaWithFormItem} from "../../Common/Input";
import {useDispatch, useSelector} from "react-redux";
import {loadProjectTags} from "../../../services/tags";
import {
    BuildFilled,
    ClockCircleFilled,
    CopyOutlined,
    EnvironmentOutlined,
    InfoCircleOutlined,
    MailOutlined,
    PaperClipOutlined,
    TeamOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import {ProjectsWithRef} from "./Filters/Projects";

import {loadTeams} from "../../../services/team";
import {createOrUpdate, loadProductionBooks} from "../../../services/productionBooks";

import {ScheduleBlock} from "./ScheduleBlock";
import {TeamBlock} from "./TeamBlock";
import {LocationsBlock} from "./LocationsBlock";
import {AttachmentsBlock} from "./AttachmentsBlock";
import "./ProductionBooksDetailsPanel.scss";
import usePermissions from "../../../hooks/usePermissions";
import {noContactOption, noTeamOption} from "./Filters/Team";
import {isOptionSelected} from "./Filters/TreeSelectFilter";
import {noDepartmentsOption} from "./Filters/Departments";
import {noCategoriesOption} from "./Filters/Categories";
import {noStatusesOption} from "./Filters/Statuses";
import EmailsBlock from "./EmailsBlock";
import {RequirementsBlock} from "./RequirementsBlock";
import AuditFormItem from "../../Common/AuditFormItem";
import NotesBlock, {noNoteDepartmentsOption} from "./NotesBlock";

import InventoriesBlock, {
    noInventoryDepartmentsOption, noInventoryItemKeywordsOption, noInventoryItemTypesOption
} from "./InventoriesBlock";

const InformationHeader = (<>
    <InfoCircleOutlined/> Production Book Information
</>);

const prepareFormData = (data) => {

    const formData = {
        ...data,
        URL: getPublicUrl(data),
        SubscriptionUrl: getSubscriptionUrl(data),

        Projects: data.IsAllProjects ? [] : (data.Schedule.Projects || []).map((p) => (_.isNumber(p) ? p.toString() : p.Id.toString())),
        ScheduleIgnoreProjects: (data.Schedule.ScheduleIgnoreProjects || []).map((p) => (_.isNumber(p) ? p.toString() : p.Id.toString())),

        NoteTags: [...(data.NoteTags.map((x) => x.AccountTagId.toString()) || []), data.IsNoNoteDepartment ? noNoteDepartmentsOption.value : null,].filter(_.isString),

        Inventory: {
            DepartmentTags: [...(data.Inventory?.DepartmentTags.map((x) => x.AccountTagId.toString()) || []), data.IsNoInventoryDepartment ? noInventoryDepartmentsOption.value : null,].filter(_.isString),
            ItemKeywordTags: [...(data.Inventory?.ItemKeywordTags.map((x) => x.AccountTagId.toString()) || []), data.IsNoInventoryItemKeyword ? noInventoryItemKeywordsOption.value : null,].filter(_.isString),
            ItemTypeTags: [...(data.Inventory?.ItemTypeTags.map((x) => x.AccountTagId.toString()) || []), data.IsNoInventoryItemType ? noInventoryItemTypesOption.value : null,].filter(_.isString),
        },

        Schedule: {
            ...data.Schedule,
            TeamMembers: [...(data.Schedule?.TeamMembers || []), data.Schedule.IsNoTeam ? {Id: noTeamOption.value} : null, data.Schedule.IsNoAssignedContact ? {Id: noContactOption.value} : null,].filter(_.isObject),
            Locations: (data.Schedule.Locations || []).filter(_.isObject),
            DepartmentTags: [...(data.Schedule?.DepartmentTags.map((x) => x.AccountTagId.toString()) || []), data.Schedule.IsNoDepartment ? noDepartmentsOption.value : null,].filter(_.isString),
            CategoryTags: [...(data.Schedule?.CategoryTags.map((x) => x.AccountTagId.toString()) || []), data.Schedule.IsNoCategory ? noCategoriesOption.value : null,].filter(_.isString),
            StatusTags: [...(data.Schedule?.StatusTags.map((x) => x.AccountTagId.toString()) || []), data.Schedule.IsNoneTaskStatus ? noStatusesOption.value : null,].filter(_.isString),
        },
    };
    return formData;
};

const getSubscriptionUrl = (data) => {
    if (!data.SecurityKey) {
        return "—";
    }
    const host = getFeedsHost();
    return `webcal://${host}/feeds/filters/${data.AccountId}/${data.Id}/${data.SecurityKey}.ics`;
};

export const getPublicUrl = (data) => {
    if (!data.PublicSecurityKey) {
        return "—";
    }

    const host = getPagesHost();
    return `${window.location.protocol}//${host}/p/filters/${data.Id}/${data.PublicSecurityKey}`;
};

const ProductionBooksDetailsPanel = ({type}) => {
    const itemToEdit = useSelector((state) => state.detailsPanel.item);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const firstInputRef = useRef();
    const nextInputRef = useRef();
    const [editItemState, setEditItemState] = useState(NewRecordState.Empty);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [scheduleCount, setScheduleCount] = useState(null);
    const [teamCount, setTeamCount] = useState(null);
    const [locationsCount, setLocationsCount] = useState(null);
    const [attachmentsCount, setAttachmentsCount] = useState(null);
    const [, setIsFeedEnabled] = useState(false);
    const [, setIsPubliclyAccessible] = useState(false);
    const [, setIsScheduleEnabled] = useState(false);
    const [, setIsAttachmentsEnabled] = useState(false);
    const [, setIsRequirementsEnabled] = useState(false);

    const {canEditProductionBook, isProjectManager} = usePermissions();
    const projects = useSelector((state) => state.projects.projects);
    const allProjectIds = projects.map((p) => p.Id);
    const canEditThisProductionBook = _.isNil(itemToEdit) || canEditProductionBook(itemToEdit);

    const activePortfolio = useSelector((state) => state.projects.activePortfolio);
    const userProjects = useSelector((state) => state.projects.projects);
    const timeZoneId = useSelector((state) => state.projects.activePortfolio?.TimeZoneId);
    const resources = useSelector((state) => state.resources.resources);

    useEffect(() => {
        if (selectedProjects.length > 0) {
            loadTeams(activePortfolio.Id, selectedProjects);
            loadProjectTags(selectedProjects);
        }
    }, [activePortfolio.Id, selectedProjects.join(":")]);

    useEffect(() => {
        if (!itemToEdit) {
            form.setFieldsValue({
                IsScheduleEnabled: true, IsPubliclyAccessible: true, IsFeedEnabled: true,
            });
            if (isProjectManager) {
                form.setFieldsValue({
                    Schedule: {
                        IsAllProjects: false, Projects: userProjects, IgnoreProjects: userProjects,
                    },
                });
                setSelectedProjects(userProjects.map((p) => p.Id));
            } else {
                setSelectedProjects(allProjectIds);
            }
            setTimeout(() => firstInputRef.current?.focus(), 200);
            return;
        }

        if (editItemState === NewRecordState.RequiredFieldsSubmitted) {
            setTimeout(() => nextInputRef.current.focus(), 200);
        }

        setEditItemState(NewRecordState.ReadyForEdit);
        const formData = prepareFormData(itemToEdit);

        form.setFieldsValue(formData);

        if (!isAllProjects || projectIds.length > 0) {
            const projectToSelect = itemToEdit.Schedule.IsAllProjects ? allProjectIds : projectIds;
            if (_.xor(selectedProjects, projectToSelect).length > 0) setSelectedProjects(projectToSelect);
        } else {
            setSelectedProjects(allProjectIds);
        }

        setIsFeedEnabled(formData.IsFeedEnabled);
        setIsScheduleEnabled(formData.IsScheduleEnabled);
        setIsPubliclyAccessible(formData.IsPubliclyAccessible);
        setIsAttachmentsEnabled(formData.IsAttachmentsEnabled);
        setIsRequirementsEnabled(formData.IsRequirementsEnabled);
    }, [itemToEdit]);

    const isAllProjects = itemToEdit?.Schedule.IsAllProjects || false;
    const projectIds = itemToEdit?.Schedule.Projects.map((p) => p.Id) || [];

    const prepareAndUpdate = (finishedForm) => {
        setIsFeedEnabled(finishedForm.IsFeedEnabled);
        setIsScheduleEnabled(finishedForm.IsScheduleEnabled);
        setIsPubliclyAccessible(finishedForm.IsPubliclyAccessible);
        setIsAttachmentsEnabled(finishedForm.IsAttachmentsEnabled);
        setIsRequirementsEnabled(finishedForm.IsRequirementsEnabled);

        const startDate = finishedForm.Schedule?.StartDate !== undefined ? finishedForm.Schedule?.StartDate : finishedForm.Schedule?.StartDateTime;
        const endDate = finishedForm.Schedule?.EndDate !== undefined ? finishedForm.Schedule?.EndDate : finishedForm.Schedule?.EndDateTime;
        const projects = (finishedForm.Schedule?.Projects || []).map((p) => p.Id);

        setSelectedProjects(projects);

        const TeamMembers = finishedForm.Schedule?.TeamMembers?.map((l) => l.Id).filter((x) => _.isNumber(x) && !isNaN(x)) || [];
        const ScheduleTeamMemberContacts = finishedForm.Schedule?.ScheduleTeamMemberContacts?.map((l) => l.Id) || [];
        const ScheduleLocationContacts = finishedForm.Schedule?.ScheduleLocationContacts?.map((l) => l.Id) || [];
        const ScheduleIgnoreProjects = finishedForm.Schedule?.ScheduleIgnoreProjects?.map((l) => l.Id) || [];
        const ScheduleLocations = finishedForm.Schedule?.Locations?.map((l) => l.Id) || [];

        const LocationsLocations = finishedForm.Locations?.Locations?.map((i) => i.Id) || [];
        const LocationsLocationContacts = finishedForm.Locations?.LocationsLocationContacts?.map((l) => l.Id) || [];

        const CategoriesTags = finishedForm.Schedule?.CategoryTags?.map((x) => parseInt(x, 10)).filter((x) => _.isNumber(x) && !isNaN(x)) || [];
        const DepartmentTags = finishedForm.Schedule?.DepartmentTags?.map((x) => parseInt(x, 10)).filter((x) => _.isNumber(x) && !isNaN(x)) || [];
        const StatusTags = finishedForm.Schedule?.StatusTags?.map((x) => parseInt(x, 10)).filter((x) => _.isNumber(x) && !isNaN(x)) || [];
      
        const InventoryDepartmentTags = finishedForm.Inventory?.DepartmentTags?.map((x) => parseInt(x, 10)).filter((x) => _.isNumber(x) && !isNaN(x)) || [];
        const InventoryItemKeywordTags = finishedForm.Inventory?.ItemKeywordTags?.map((x) => parseInt(x, 10)).filter((x) => _.isNumber(x) && !isNaN(x)) || [];
        const InventoryItemTypeTags = finishedForm.Inventory?.ItemTypeTags?.map((x) => parseInt(x, 10)).filter((x) => _.isNumber(x) && !isNaN(x)) || [];

        const IsAllProjects = !_.isNil(finishedForm.Schedule?.IsAllProjects) ? finishedForm.Schedule.IsAllProjects : true;
        const TeamProjectTags = finishedForm.TeamGroups?.GroupTags?.map((pt) => ({
            ...pt, ProjectTagId: pt.ProjectTagId || pt.Id
        }));

        const NoteTags = finishedForm.NoteTags?.map((x) => parseInt(x, 10))
            .filter(_.isNumber)
            .filter((x) => !isNaN(x)) || [];

        let FilterLayoutType = finishedForm.FilterLayoutType;

        switch (finishedForm.FilterLayout) {
            case ApiFilterLayout.Year: {
                FilterLayoutType = ApiFilterLayoutType.GroupedByProject;
                break;
            }
            case ApiFilterLayout.Week: {
                FilterLayoutType = ApiFilterLayoutType.GroupedByDay;
                break;
            }
            case null:
            case undefined: {
                FilterLayoutType = ApiFilterLayoutType.GroupedByDay;
                break;
            }
            default: {
                FilterLayoutType = finishedForm.FilterLayoutType;
                break;
            }
        }

        const updatePayload = {
            PortfolioId: activePortfolio.Id,
            Id: itemToEdit?.Id,
            Name: finishedForm.Name,
            IsScheduleEnabled: finishedForm.IsScheduleEnabled,
            IsPublicUrlEnabled: finishedForm.IsPubliclyAccessible,
            IsFeedEnabled: finishedForm.IsFeedEnabled,

            FilterLayout: finishedForm.FilterLayout,
            FilterLayoutType,
            Description: finishedForm.Description,
            PublicNotes: finishedForm.PublicNotes,
            TimeZoneId: itemToEdit?.Id ? finishedForm.TimeZoneId : timeZoneId,
            NoteTags,
            Schedule: {
                IsAllProjects,
                FilterIncludeType: finishedForm.Schedule?.FilterIncludeType,
                FilterCrewType: finishedForm.Schedule?.FilterCrewType,
                Projects: projects,
                ScheduleLocationContacts,
                Locations: ScheduleLocations,
                TeamMembers,
                ScheduleTeamMemberContacts,
                ScheduleIgnoreProjects,
                TaskType: finishedForm.Schedule?.TaskType,
                IsOnlyConfirmedBooking: finishedForm.Schedule?.IsOnlyConfirmedBooking,
                TaskStatus: finishedForm.Schedule?.TaskStatus,
                OptionalTeam: finishedForm.Schedule?.OptionalTeam,
                OptionalTeamRoles: finishedForm.Schedule?.OptionalTeamRoles,
                OptionalNotes: finishedForm.Schedule?.OptionalNotes,
                OptionalLocation: finishedForm.Schedule?.OptionalLocation,
                OptionalStatus: finishedForm.Schedule?.OptionalStatus,
                OptionalCategories: finishedForm.Schedule?.OptionalCategories,
                OptionalDepartments: finishedForm.Schedule?.OptionalDepartments,
                OptionalReports: finishedForm.Schedule?.OptionalReports,
                CategoriesTags,
                DepartmentTags,
                StatusTags,
                RangeType: finishedForm.Schedule?.RangeType,
                StartDate: startDate,
                EndDate: endDate,
                IsNoTeam: finishedForm.Schedule?.IsNoTeam,
                IsNoAssignedContact: finishedForm.Schedule?.IsNoAssignedContact,
                IsNoDepartment: isOptionSelected(noDepartmentsOption.value, finishedForm.Schedule?.DepartmentTags),
                IsNoCategory: isOptionSelected(noCategoriesOption.value, finishedForm.Schedule?.CategoryTags),
                IsNoneTaskStatus: isOptionSelected(noStatusesOption.value, finishedForm.Schedule?.StatusTags),
            },
            Attachments: {
                Attachments: finishedForm.Attachments?.Attachments?.map((l) => l.Id) || [],
            },
            IsAttachmentsEnabled: finishedForm.IsAttachmentsEnabled,
            IsTeamGroupsEnabled: finishedForm.IsTeamGroupsEnabled,
            IsNotesEnabled: finishedForm.IsNotesEnabled,
            IsLocationsEnabled: finishedForm.IsLocationsEnabled,
            IsInventoryEnabled: finishedForm.IsInventoryEnabled,
            IsRequirementsEnabled: finishedForm.IsRequirementsEnabled,

            IsNoNoteDepartment: isOptionSelected(noNoteDepartmentsOption.value, finishedForm.NoteTags),

            IsNoInventoryDepartment: isOptionSelected(noInventoryDepartmentsOption.value, finishedForm.Inventory?.DepartmentTags),
            IsNoInventoryItemKeyword: isOptionSelected(noInventoryItemKeywordsOption.value, finishedForm.Inventory?.ItemKeywordTags),
            IsNoInventoryItemType: isOptionSelected(noInventoryItemTypesOption.value, finishedForm.Inventory?.ItemTypeTags),

            Locations: {
                LocationColumns: finishedForm.Locations?.LocationColumns || [],
                LocationsLocationContacts,
                Locations: LocationsLocations,
            },
            TeamGroups: {
                ...finishedForm.TeamGroups, TeamProjectTags,
            },
            Requirements: {
                RequirementColumns: finishedForm.Requirements?.RequirementColumns || [],
            },
            Inventory: {
                DepartmentTags: InventoryDepartmentTags,
                ItemKeywordTags: InventoryItemKeywordTags,
                ItemTypeTags: InventoryItemTypeTags,
            },
        };

        createOrUpdate(updatePayload)
            .then((response) => {
                if (!itemToEdit?.Id) {
                    dispatch(continueEdit({item: response.FilterDto}));
                    setEditItemState(NewRecordState.ReadyForEdit);
                } else {
                    dispatch(continueEdit({
                        item: response.FilterDto,
                    }));
                }
                return loadProductionBooks(activePortfolio.Id);
            })
            .catch((err) => {
                if (!isRequestCanceled(err)) {
                    const errorText = errorFromHttpResponse(err);
                    message.error(errorText);
                    console.error(err);
                }
            });
    };

    const onFinish = (finishedForm) => {
        if (!canEditThisProductionBook) return;

        if (editItemState === NewRecordState.Empty) {
            setEditItemState(NewRecordState.RequiredFieldsSubmitted);
        }

        prepareAndUpdate(finishedForm);
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            onFinish(values);
        } catch (err) {
            console.error(err);
        }
    };

    const onCopyUrl = (url) => {
        if (!url) return;

        navigator.clipboard.writeText(url);
        message.info("Copied");
    };

    const onPageCheckboxClick = (target, name) => {
        if (target.checked) {
            const formData = prepareFormData(itemToEdit);
            onFinish({...formData, [name]: true});
        } else {
            save();
        }
    };

    const isOptionalFieldDisabled = editItemState !== NewRecordState.ReadyForEdit || !canEditThisProductionBook;
    const isNameFieldDisabled = editItemState === NewRecordState.ReadyForEdit && !canEditThisProductionBook;

    const expandedPanelKeys = ["Information", "Schedule", "Team", "Locations", "Attachments", "Requirements", "Emails", "Notes", "Inventories"];

    return (<div className="side-panel with-collapse">
        <Collapse defaultActiveKey={expandedPanelKeys}>
            <Collapse.Panel key="Information" header={InformationHeader}>
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal" size="middle">
                    <InputWithFormItemWithRef
                        ref={firstInputRef}
                        name="Name"
                        label="Name"
                        hasFeedback
                        rules={[{required: true, message: "Name"}]}
                        onChanged={save}
                        disabled={isNameFieldDisabled}
                        placeholder="Enter a name to create new Production Book"
                    />

                    <Form.Item
                        label="Enabled"
                        name="IsPubliclyAccessible"
                        valuePropName="checked"
                        className="ant-form-item-without-validation">
                        <Switch disabled={isOptionalFieldDisabled} onChange={save}/>
                    </Form.Item>
                    <Form.Item label="URL" className="ant-form-item-without-validation">
                        {form.getFieldValue("IsPubliclyAccessible") && (<>
                            <a href={form.getFieldValue("URL")} target="blank">
                                <span className="url">{form.getFieldValue("URL")} </span>
                            </a>
                            <span className="url-copy" onClick={() => onCopyUrl(form.getFieldValue("URL"))}>
                    <CopyOutlined/>
                  </span>
                        </>)}
                        {!form.getFieldValue("IsPubliclyAccessible") && (<>
                            <span className="url disabled">{form.getFieldValue("URL")} </span>
                            <span className="url-copy disabled">
                    <CopyOutlined/>
                  </span>
                        </>)}
                    </Form.Item>

                    <Form.Item label="Projects" name="Schedule" className="ant-form-item-without-validation">
                        <ProjectsWithRef
                            ref={nextInputRef}
                            disabled={isOptionalFieldDisabled}
                            onChanged={save}
                            isAllProjects={isAllProjects}>
                        </ProjectsWithRef>
                    </Form.Item>

                    <Form.Item label="Pages">
                        <Row>
                            <Col span={24}>
                                <div className="pages-block">
                                    <Form.Item
                                        name="IsScheduleEnabled"
                                        labelAlign="right"
                                        label=""
                                        style={{width: 120}}
                                        valuePropName="checked"
                                        className="ant-form-item-without-validation">
                                        <Checkbox
                                            disabled={isOptionalFieldDisabled}
                                            onChange={({target}) => onPageCheckboxClick(target, "IsScheduleEnabled")}>
                                            Schedule
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="IsNotesEnabled"
                                        labelAlign="right"
                                        label=""
                                        style={{width: 100}}
                                        valuePropName="checked"
                                        className="ant-form-item-without-validation">
                                        <Checkbox
                                            disabled={isOptionalFieldDisabled}
                                            onChange={({target}) => onPageCheckboxClick(target, "IsNotesEnabled")}>
                                            Notes
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="IsTeamGroupsEnabled"
                                        labelAlign="right"
                                        label=""
                                        style={{width: 50}}
                                        valuePropName="checked"
                                        className="ant-form-item-without-validation">
                                        <Checkbox
                                            disabled={isOptionalFieldDisabled}
                                            onChange={({target}) => onPageCheckboxClick(target, "IsTeamGroupsEnabled")}>
                                            Team
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="IsLocationsEnabled"
                                        labelAlign="right"
                                        label=""
                                        style={{width: 120}}
                                        valuePropName="checked"
                                        className="ant-form-item-without-validation">
                                        <Checkbox
                                            disabled={isOptionalFieldDisabled}
                                            onChange={({target}) => onPageCheckboxClick(target, "IsLocationsEnabled")}>
                                            Locations
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="IsAttachmentsEnabled"
                                        labelAlign="right"
                                        label=""
                                        style={{width: 110}}
                                        valuePropName="checked"
                                        className="ant-form-item-without-validation">
                                        <Checkbox
                                            disabled={isOptionalFieldDisabled}
                                            onChange={({target}) => onPageCheckboxClick(target, "IsAttachmentsEnabled")}>
                                            Attachments
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="IsRequirementsEnabled"
                                        labelAlign="right"
                                        label=""
                                        style={{width: 120}}
                                        valuePropName="checked"
                                        className="ant-form-item-without-validation">
                                        <Checkbox
                                            disabled={isOptionalFieldDisabled}
                                            onChange={({target}) => onPageCheckboxClick(target, "IsRequirementsEnabled")}>
                                            Requirements
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        name="IsInventoryEnabled"
                                        labelAlign="right"
                                        label=""
                                        style={{width: 100}}
                                        valuePropName="checked"
                                        className="ant-form-item-without-validation">
                                        <Checkbox
                                            disabled={isOptionalFieldDisabled}
                                            onChange={({target}) => onPageCheckboxClick(target, "IsInventoryEnabled")}>
                                            Inventory
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form.Item>

                    <TextAreaWithFormItem
                        label="Details (Private)"
                        name="Description"
                        onChanged={save}
                        disabled={isOptionalFieldDisabled}
                        autoSize={{minRows: 2}}
                        placeholder="Enter details"></TextAreaWithFormItem>

                    <TextAreaWithFormItem
                        label="General Notes (Public)"
                        name="PublicNotes"
                        onChanged={save}
                        disabled={isOptionalFieldDisabled}
                        useTextEditor={true}
                        placeholder="Enter general notes"></TextAreaWithFormItem>

                    {itemToEdit && (<>
                        <AuditFormItem item={itemToEdit} label="Created" timeProp="CreatedAt"
                                       userProp="CreatedBy"/>
                        <AuditFormItem item={itemToEdit} label="Last Updated" timeProp="UpdatedAt"
                                       userProp="UpdatedBy"/>
                    </>)}
                </Form>
            </Collapse.Panel>
            {form.getFieldValue("IsScheduleEnabled") && (<Collapse.Panel
                key="Schedule"
                header={<>
                    <ClockCircleFilled/> Schedule Page {scheduleCount == null ? "(Disabled)" : ""}
                </>}>

                <ScheduleBlock
                    form={form}
                    onChanged={save}
                    disabled={isOptionalFieldDisabled}
                    selectedProjects={selectedProjects}
                    isAllProjects={isAllProjects}
                    setScheduleCount={(val) => setScheduleCount(val)}></ScheduleBlock>
            </Collapse.Panel>)}

            {form.getFieldValue("IsNotesEnabled") && (<Collapse.Panel
                key="Notes"
                header={<>
                    <UnorderedListOutlined/> Notes Page
                </>}>
                <Form form={form} labelCol={{span: 8}} wrapperCol={{span: 16}} layout="horizontal"
                      size="middle">
                    <Form.Item label="Departments" name={["NoteTags"]}
                               className="ant-form-item-without-validation">
                        <NotesBlock form={form}
                                    onChanged={save}
                                    selectedProjects={selectedProjects}>
                        </NotesBlock>
                    </Form.Item>
                </Form>
            </Collapse.Panel>)}

            {form.getFieldValue("IsTeamGroupsEnabled") && (<Collapse.Panel
                key="Team"
                header={<>
                    <TeamOutlined/> Team Page
                    ({teamCount == null ? "Disabled" : `${teamCount} Team Members`})
                </>}>
                <TeamBlock
                    disabled={isOptionalFieldDisabled}
                    form={form}
                    onChanged={save}
                    selectedProjects={selectedProjects}
                    setTeamCount={(val) => {
                        if (val !== teamCount) setTeamCount(val);
                    }}></TeamBlock>
            </Collapse.Panel>)}
            {form.getFieldValue("IsLocationsEnabled") && (<Collapse.Panel
                key="Locations"
                header={<>
                    <EnvironmentOutlined/> Locations Page
                    ({locationsCount == null ? "Disabled" : `${locationsCount} Locations`})
                </>}>
                <LocationsBlock
                    disabled={isOptionalFieldDisabled}
                    form={form}
                    onChanged={save}
                    selectedProjects={selectedProjects}
                    setLocationsCount={(val) => {
                        if (val !== locationsCount) setLocationsCount(val);
                    }}></LocationsBlock>
            </Collapse.Panel>)}
            {form.getFieldValue("IsAttachmentsEnabled") && (<Collapse.Panel
                key="Attachments"
                header={<>
                    <PaperClipOutlined/> Attachments Page (
                    {attachmentsCount == null ? "Disabled" : `${attachmentsCount} Attachments`})
                </>}>
                <AttachmentsBlock
                    onChanged={save}
                    disabled={isOptionalFieldDisabled}
                    form={form}
                    selectedProjects={selectedProjects}
                    setAttachmentsCount={(val) => setAttachmentsCount(val)}></AttachmentsBlock>
            </Collapse.Panel>)}
            {form.getFieldValue("IsRequirementsEnabled") && (<Collapse.Panel
                key="Requirements"
                header={<>
                    <BuildFilled/> Requirements Page
                </>}>
                <RequirementsBlock onChanged={save} disabled={isOptionalFieldDisabled} form={form}/>
            </Collapse.Panel>)}

            {form.getFieldValue("IsInventoryEnabled") && (
                <Collapse.Panel key="Inventories" header={<><UnorderedListOutlined/> Inventory Page</>}>
                    <InventoriesBlock
                        form={form}
                        onChanged={save}
                        resources={resources}
                    >
                    </InventoriesBlock>
                </Collapse.Panel>)}

            <Collapse.Panel
                key="Emails"
                header={<>
                    <MailOutlined/> Email Notifications
                </>}>
                <EmailsBlock form={form} emailsHistory={itemToEdit?.FilterEmailHistory}/>
            </Collapse.Panel>
        </Collapse>
    </div>);
};

export default ProductionBooksDetailsPanel;
