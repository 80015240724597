import React, {useEffect} from 'react';
import {Input} from 'antd';
import './SearchBar.scss';
import _ from "lodash";

const SearchBar = ({searchTerm, setSearchTerm, rawItems, setFilteredItems}) => {
    useEffect(() => {
        if (Array.isArray(rawItems)) {
            const lowercasedFilter = searchTerm.toLowerCase();
            const filteredData = rawItems.filter(item => {
                return Object.keys(item).some(key => {
                    if (key === 'Id') return false;
                    const value = item[key];
                    if (Array.isArray(value)) {
                        return value.some(subItem =>
                            String(subItem.Name || subItem).toLowerCase().includes(lowercasedFilter)
                        );
                    } else if (typeof value === 'object' && value !== null) {
                        return Object.values(value).some(subValue =>
                            String(subValue).toLowerCase().includes(lowercasedFilter)
                        );
                    }
                    return String(value).toLowerCase().includes(lowercasedFilter);
                });
            });
            setFilteredItems(_.sortBy(filteredData, (m) => m.Name));
        }
    }, [searchTerm, rawItems, setFilteredItems]);

    return (
        <div className="search-bar">
            <Input
                placeholder="Search Your Inventory"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </div>
    );
};

export default SearchBar;