import React, {useState} from "react";
import {useSelector} from "react-redux";
import "./Home.scss";
import SearchBar from "./SearchBar/SearchBar";
import InventoryTable from "./InventoryTable/InventoryTable";
import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../AppFooter/AppFooter";
import QrBarcodeScanner from "../QrBarcodeScanner/QrBarcodeScanner";
import {Footer, Header} from "antd/es/layout/layout";

const Home = () => {
    const rawItems = useSelector((state) => state.resources.resources);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredItems, setFilteredItems] = useState([]);

    const [isQrScannerOpen, setIsQrScannerOpen] = useState(false);

    return (<>
        <Header>
            <AppHeader/>
        </Header>
        <SearchBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            rawItems={rawItems}
            setFilteredItems={setFilteredItems}
        />
        <InventoryTable items={filteredItems}/>
        <Footer className="footer">
            <AppFooter
                isQrScannerOpen={isQrScannerOpen}
                setIsQrScannerOpen={setIsQrScannerOpen}
            />
        </Footer>
        <QrBarcodeScanner isQrScannerOpen={isQrScannerOpen} setIsQrScannerOpen={setIsQrScannerOpen}/>
    </>);
};

export default Home;
