import {TagsFilled} from "@ant-design/icons";
import {Modal, Form, message, Button} from "antd";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {TagType} from "../../../constants";
import {createTag} from "../../../services/tags";
import {InputWithFormItemWithRef, SelectWithFormItem} from "../Input";
import {iconOptions} from "../../../helpers/Tags";
import ColorPicker from "../../../components/Common/ColorPicker/ColorPicker";

const tagNames = {
    [TagType.Category]: 'Category',
    [TagType.Department]: 'Department',
    [TagType.Group]: 'Group',
    [TagType.ItemKeyword]: 'Item Keyword',
    [TagType.ItemType]: 'Item Type',
    [TagType.ItemColor]: 'Item Color',
    [TagType.Role]: 'Role',
    [TagType.TaskStatus]: 'Status',
    null: '',
};

const CreateTag = ({tagType, onClose, onCreated, defaultName}) => {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const activePortfolio = useSelector((state) => state.projects.activePortfolio);

    const tags = {
        [TagType.Category]: useSelector((state) => state.tags.categories),
        [TagType.Department]: useSelector((state) => state.tags.departments),
        [TagType.Group]: useSelector((state) => state.tags.groups),
        [TagType.ItemKeyword]: useSelector((state) => state.tags.itemKeywords),
        [TagType.ItemType]: useSelector((state) => state.tags.itemTypes),
        [TagType.ItemColor]: useSelector((state) => state.tags.itemColors),
        [TagType.Role]: useSelector((state) => state.tags.roles),
        [TagType.TaskStatus]: useSelector((state) => state.tags.statuses),
    };
    const items = tags[tagType];

    const handleOk = async () => {
        let finishedForm;
        try {
            finishedForm = await form.validateFields();
        } catch (err) {
            // Handle validation errors if needed
        }

        if (!finishedForm) return;

        try {
            setLoading(true);

            const tag = await createTag({
                AccountId: activePortfolio.Id,
                Name: finishedForm.Name,
                Type: tagType,
                Icon: finishedForm.Icon,
            });
            if (onCreated) onCreated(tag, tagType);

            message.success(`The ${tagNames[tagType]} ${finishedForm.Name} was created.`);
            form.resetFields();
            onClose();
        } catch (err) {
            message.error('Server error');
        } finally {
            setLoading(false);
        }
    };

    const close = () => {
        setLoading(false);
        onClose();
    };

    const handleColorChange = (color) => {
        form.setFieldsValue({Icon: color});
    };

    const onValuesChange = (changedValues) => {
        if (changedValues.Icon) {
            form.setFieldsValue({Icon: changedValues.Icon});
        }
    };

    const isVisible = tagType !== null;

    useEffect(() => {
        if (isVisible) form.resetFields();
    }, [isVisible]);

    const initialColor = form.getFieldValue("Icon");

    return (
        <Modal
            confirmLoading={isLoading}
            title={
                <>
                    <TagsFilled/> New {tagNames[tagType]}
                </>
            }
            visible={isVisible}
            maskClosable={false}
            onOk={handleOk}
            onCancel={close}
            okText="Save"
        >
            <Form
                initialValues={{Name: defaultName}}
                form={form}
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                layout="horizontal"
                size="middle"
                onValuesChange={onValuesChange}
            >
                <InputWithFormItemWithRef
                    name="Name"
                    label={tagNames[tagType]}
                    hasFeedback
                    rules={[
                        {required: true, message: `Please input your ${tagNames[tagType].toLowerCase()}`},
                        {
                            validator: (_, value) =>
                                !items.find((i) => i.Name.toLowerCase() === (value || '').toLowerCase())
                                    ? Promise.resolve()
                                    : Promise.reject(new Error(`This ${tagNames[tagType].toLowerCase()} already exists.`)),
                        },
                    ]}
                    placeholder={`Enter a new ${tagNames[tagType].toLowerCase()}`}
                />
                {tagType === TagType.TaskStatus && (
                    <SelectWithFormItem
                        allowClear
                        label="Icon (optional)"
                        name="Icon"
                        mode="single"
                        style={{width: '100%'}}
                        placeholder="Select an icon"
                        options={iconOptions}
                    />
                )}
                {tagType === TagType.ItemColor && (
                    <Form.Item
                        label="Color"
                        name="Icon"
                        rules={[{required: true, message: 'Please select a color'}]}
                    >
                        <ColorPicker
                            initialColor={initialColor}
                            onColorChange={handleColorChange}
                        />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

export default CreateTag;