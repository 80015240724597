import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import Drift from "react-driftjs";
import {DriftAppId} from "../../constants";

const LiveChatWidget = () => {
    const location = useLocation();

    useEffect(() => {
        const handleDriftReady = (api) => {
            const hideDrift = location.pathname.includes("campaign");
            if (hideDrift) {
                api.widget.hide();
            } else {
                api.widget.show();
            }
        };

        if (window.drift) {
            window.drift.on('ready', handleDriftReady);
        }

        return () => {
            if (window.drift) {
                window.drift.off('ready', handleDriftReady);
            }
        };
    }, [location]);

    return <Drift appId={DriftAppId}/>;
};

export default LiveChatWidget;
