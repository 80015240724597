import React from 'react';
import {Button} from 'antd';
import './IconButton.scss';

const IconButton = ({icon, text, ...props}) => (
    <Button {...props} className="icon-button">
        <div className="icon">{icon}</div>
        <div className="text">{text}</div>
    </Button>
);

export default IconButton;
